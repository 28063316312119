import React, { useState, useEffect } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
} from '@material-ui/core';
import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import ReactHtmlParser from 'react-html-parser';

import ModeEditOutlineTwoToneIcon from '@mui/icons-material/ModeEditOutlineTwoTone';

import Button from 'react-bootstrap/Button';
import { Link } from 'react-router-dom';
import '../../index.css'
import request from "../../../utils/http-client";

const Data = () => {
  const [data, setData] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const fetchData = async () => {
    const response = await fetch(
      `${process.env.REACT_APP_BACKEND}/api/get-packages`
    );
    const result = await response.json();
    setData(result);
  };
  useEffect(() => {
    fetchData();
  }, []);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const DeletePartnership = async (id) => {
    await request({
      method: "DELETE",
      url: `${process.env.REACT_APP_BACKEND}/api/delete-packages/${id}`,
    });
    fetchData();
  };
  return (
    <div className="content-wrapper">
      <div className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1 className="m-0"> Packages</h1>
            </div>
            {/* /.col */}
            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                <Link to="/packages-new">
                  <Button variant="primary" size="lg">
                    Add New
                  </Button>
                </Link>
              </ol>
            </div>
            {/* /.col */}
          </div>
          {/* /.row */}
        </div>
        {/* /.container-fluid */}
      </div>
      <div className="mx-3">
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>S.no #</TableCell>
                <TableCell>Packages Title</TableCell>
                <TableCell>Packages Description</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Array.isArray(data) && data.length > 0 ? (
                data
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => (
                    <TableRow key={row._id}>
                      <TableCell>{index + 1 + page * rowsPerPage}</TableCell>
                      <TableCell>{row.packagetitle}</TableCell>
                      <TableCell>{ReactHtmlParser(row.packagedesc)}</TableCell>

                      <TableCell>
                        <Link to={"/update-packages/" + row._id}>
                          <ModeEditOutlineTwoToneIcon></ModeEditOutlineTwoToneIcon>
                        </Link>
                        <DeleteTwoToneIcon
                          onClick={() => DeletePartnership(row._id)}
                        ></DeleteTwoToneIcon>
                      </TableCell>
                    </TableRow>
                  ))
              ) : (
                <TableRow>
                  <TableCell colSpan={6}>No records found.</TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={data.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </TableContainer>
      </div>
    </div>
  );
};

export default Data;
