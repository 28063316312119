import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Col, Container, Row } from 'react-bootstrap'
import Button from 'react-bootstrap/Button';

import '../ReFunds/ReFunds.css'

const ThirdSec = () => {
    const [eventstitle, setName] = useState('');
    const [description, setDesc] = useState('');
    const [excerpt, setExcerpt] = useState('');
    const [youtubevideolink, setyoutubevideolink] = useState('');
    const [file, setFile] = useState();
    const [fileName, setFileName] = useState("");
    const [error, SetError]=useState("");
    const params = useParams();

    const getTeamDetails = async () => {
        console.warn(params);
        let result = await fetch(`${process.env.REACT_APP_BACKEND}/api/update-events/${params.id}`);
        result = await result.json();
        setName(result.eventstitle);
        setDesc(result.description);
        setExcerpt(result.excerpt);
        setFileName(result.document)
        setyoutubevideolink(result.youtubevideolink)
      };
    
      useEffect(() => {
        getTeamDetails();
      }, []);
  return (
        <section className='refunds-service-sec-1'>
            <Container fluid>
                <Row >
                    <Col className=''>
                        <h1>{eventstitle}</h1>
                        <iframe className='mx-auto d-table' width="400" height="300" src={youtubevideolink} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen>

                        </iframe>

                    </Col>
                </Row>
            </Container>
        </section>

    )
}

export default ThirdSec