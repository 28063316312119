import React from "react";
import "./Header.css";
import { Link } from "react-router-dom";
import Navbar from "react-bootstrap/Navbar";
import Container from "react-bootstrap/Container";
import jootlogo from "../../assets/JOOT_LOGO.png";
import Nav from "react-bootstrap/Nav"; // Add this import

const Header = () => {
  const styles = {
    width: "100px",
  };

  return (
    <Navbar expand="lg">
      <Container fluid>
        <Navbar.Brand>
          <Link to="/">
            <img src={jootlogo} style={styles} alt="JOOT Logo" />
          </Link>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="justify-content-end flex-grow-1 pe-3">
            <div
              className="nav-item dropdown"
              onMouseEnter={() =>
                document
                  .getElementById("services-dropdown")
                  .classList.add("show")
              }
              onMouseLeave={() =>
                document
                  .getElementById("services-dropdown")
                  .classList.remove("show")
              }
            >
              <Link className="nav-link nav-link-url dropdown-toggle" to="#">
                Services
              </Link>
              <div className="dropdown-menu" id="services-dropdown">
                <Link className="dropdown-item" to="/services">
                  Investment Advisers
                </Link>
                <Link
                  className="dropdown-item"
                  to="/services/registered-fund-services"
                >
                  Registered Funds
                </Link>
                <Link
                  className="dropdown-item"
                  to="/services/mock-sec-exam-services"
                >
                  Mock SEC Exam
                </Link>
                <Link
                  className="dropdown-item"
                  to="/services/ria-registration-services"
                >
                  RIA Registration
                </Link>
              </div>
            </div>

            {/* Repeat the pattern for other dropdowns */}

            <div
              className="nav-item dropdown"
              onMouseEnter={() =>
                document
                  .getElementById("resources-dropdown")
                  .classList.add("show")
              }
              onMouseLeave={() =>
                document
                  .getElementById("resources-dropdown")
                  .classList.remove("show")
              }
            >
              <Link
                className="nav-link nav-link-url dropdown-toggle"
                to="/resources"
              >
                Resources
              </Link>
              <div className="dropdown-menu" id="resources-dropdown">
                <Link className="dropdown-item" to="/blog">
                  Blog
                </Link>
                <Link
                  className="dropdown-item"
                  to="/resources/compliance-newsletter"
                >
                  Newsletter
                </Link>
                { /* <Link
                  className="dropdown-item"
                  to="/resources/compliance-infographics"
                > 
                  Infographics
            </Link> */}
                <Link className="dropdown-item" to="/events">
                  Events
                </Link>
              </div>
            </div>

            {/* Repeat the pattern for other dropdowns */}

            <div
              className="nav-item dropdown"
              onMouseEnter={() =>
                document.getElementById("about-dropdown").classList.add("show")
              }
              onMouseLeave={() =>
                document
                  .getElementById("about-dropdown")
                  .classList.remove("show")
              }
            >
              <Link
                className="nav-link nav-link-url dropdown-toggle"
                to="/about"
              >
                About
              </Link>
              <div className="dropdown-menu" id="about-dropdown">
                <Link className="dropdown-item" to="/about">
                  Meet The Team
                </Link>
                <Link className="dropdown-item" to="/partnerships">
                  Our Partnerships
                </Link>
              </div>
            </div>

            <Nav.Link className="px-5">
              <Link className="nav-link-url" to="/contact">
                Contact
              </Link>
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default Header;
