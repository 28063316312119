import React from 'react'
import Footer from '../../Components/Footer/Footer'
import Header from '../../Components/Header/Header'
import Fourthsec from './Fourthsec'
import Intro from './Intro'
import Secondsec from './Secondsec'
import Thirdsec from './Thirdsec'
import { Helmet } from 'react-helmet'

const index = () => {
  return (
        <>
        <Helmet>
            <title>
            RIA Registration Services | SEC Registration
            </title>
            <meta name="description" content="Joot helps investment advisers with RIA registration services and SEC registration and help you create a strong RIA compliance program.
"/>
        </Helmet>
        <Header/>
            <React.Fragment>
                <Intro/>
                <Secondsec/>
                <Thirdsec/>
                <Fourthsec/>
            </React.Fragment>
        <Footer/>
        </>
    )
}

export default index