import React, { useState, useEffect } from "react";

import { Container, Row, Col } from "react-bootstrap";
import Accordion from "react-bootstrap/Accordion";
import { Check, Close } from "@material-ui/icons";
import request from "../../utils/http-client";

const ThirdSec = () => {
  const [accordians, setAccordians] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const result = await request({
        url: `${process.env.REACT_APP_BACKEND}/api/get-accordian`,
        method: "GET",
      });
      setAccordians(result);
    };

    fetchData();
  }, []);

  const [activeKey, setActiveKey] = useState(null);

  useEffect(() => {
    if (accordians.length > 0) {
      setActiveKey(accordians[0].id);
    }
  }, [accordians]);

  const handleClick = (eventKey) => {
    setActiveKey((prevKey) => (prevKey === eventKey ? null : eventKey));
  };

  return (
    <section className="services-third-sec">
      <Container>
        <Row>
          <Col>
            <h1 className="text-center py-3">
              Compare Our Standard vs. Full Service Packages
            </h1>
            <Accordion activeKey={activeKey} onSelect={handleClick}>
              {accordians.map((accordian, idx) => (
                <Accordion.Item eventKey={accordian.id} key={idx}>
                  <Accordion.Header>{accordian.packageName}</Accordion.Header>
                  <Accordion.Body>
                    <table className="table">
                      <thead>
                        <tr>
                          <th style={{ width: "70%" }}></th>
                          <th>Standard</th>
                          <th>Full</th>
                        </tr>
                      </thead>
                      <tbody>
                        {accordian.items.map((item, idx) => (
                          <tr key={idx}>
                            <td>{item.name}</td>
                            {item.variationType === "both" ? (
                              <>
                                <td>
                                  <Check />
                                </td>
                                <td>
                                  <Check />
                                </td>
                              </>
                            ) : (
                              <>
                                <td>
                                  {item.variationType === "standard" ? (
                                    <Check />
                                  ) : (
                                    <Close />
                                  )}
                                </td>
                                <td>
                                  {item.variationType === "full" ? (
                                    <Check />
                                  ) : (
                                    <Close />
                                  )}
                                </td>
                              </>
                            )}
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </Accordion.Body>
                </Accordion.Item>
              ))}
            </Accordion>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default ThirdSec;
