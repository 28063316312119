import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import { Link } from "react-router-dom";

const Fourthsec = () => {
  const [partner, setpartner] = useState([]);

  const fetchpartnerpage = async () => {
    const response = await fetch(
      `${process.env.REACT_APP_BACKEND}/api/get-servicespage`
    );
    const result = await response.json();
    setpartner(result);
  };
  useEffect(() => {
    fetchpartnerpage();
  }, []);
  return (
    <section className="fourth-sec">
      <Container>
        <Row>
          <Col>
            {partner.map((row, index) => (
              <React.Fragment key={index}>
                <h1 className="py-5">{row.ctatitleheading}</h1>

                <Button variant="outline-light" className="blue-btn">
                  <Link
                    className="text-decoration-none text-white"
                    to={row.ctabutton}
                  >
                    CONTACT US
                  </Link>
                </Button>
              </React.Fragment>
            ))}
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Fourthsec;
