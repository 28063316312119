import React from 'react'
import { Container, Row, Col } from 'react-bootstrap';
import img from '../../assets/compliance-graphic.png';
import Button from 'react-bootstrap/Button';
import '../Home/Home.css'
import '../Contact/contact.css'

const Intro = () => {
  return (
        <>
       <section className="hero-section introsec py-5">
      <Container>
        <Row className='py-5'>
          <Col className='py-5'>
            {/* <h1 className=' contact-intro-title'>Interested in Tailored Compliance Services?</h1> */}

          </Col>
          
        </Row>
      </Container>
    </section>
        
        </>
    )
}

export default Intro