import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import '../ReFunds/ReFunds.css'
import Card from 'react-bootstrap/Card';
import b1 from '../../assets/b1.webp'
import b2 from '../../assets/b2.webp'
import user from '../../assets/Cody_Foster.webp'
import Button from 'react-bootstrap/Button';

const SecondrySec = () => {
  return (
        <section className='refunds-service-sec-2'>
            <Container fluid>
            <Row>
                    <h2 className='py-1 text-center'>Submit your questions here and join the <br/>conversation!</h2>
                    
                </Row>

                
            </Container>
        </section>

    )
}

export default SecondrySec