import React from "react";
import Footer from "../../Components/Footer/Footer";
import Header from "../../Components/Header/Header";
import Intro from "./Intro";
import SecondrySec from "./SecondarySec";
import Thirdsec from "./Thirdsec";
import { Helmet } from "react-helmet";

const index = () => {
  return (
    <>
      <Helmet>
        <title>Joot Compliance Infographics | Compliance Update</title>
        <meta
          name="description"
          content="Download Joot's quarterly infographics and stay up to date with the latest in SEC financial compliance news.
"
        />
      </Helmet>
      <Header />
      <React.Fragment>
        <Intro />
        <SecondrySec />
        <Thirdsec />
      </React.Fragment>
      <Footer />
    </>
  );
};

export default index;
