import React, { useState,useEffect } from "react";
import { Link , useNavigate, useParams} from "react-router-dom";

import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";

import request from "../../../utils/http-client";

const UpdateHomepage = () => {
  const [headingone, setHeadingOne] = useState("");
  const [buttonone, setButtonOne] = useState("");
  const [headingtwo, setHeadingTwo] = useState("");
  const [subheadingtwo, setSubheadingTwo] = useState("");
  const [buttontwo, setButtonTwo] = useState("");
  const [descone, setDescone] = useState("");
  const [userreviewheading, setUserReviewHeading] = useState("");
  const [userreviewposition, setUserReviewPosition] = useState("");
  const [headingthree, setHeadingThree] = useState("");
  const [ctatitleheading, setCtaTitleHeading] = useState("");
  const [ctadesc, setCtaDesc] = useState("");
  const [ctabutton, setCtaButton] = useState("");
  const navigate = useNavigate();
  const params = useParams();

  const handleChange = (e) => {
    const { name, value } = e.target;
    switch (name) {
      case "headingone":
        setHeadingOne(value);
        break;
      case "buttonone":
        setButtonOne(value);
        break;
      case "headingtwo":
        setHeadingTwo(value);
        break;
      case "subheadingtwo":
        setSubheadingTwo(value);
        break;
      case "buttontwo":
        setButtonTwo(value);
        break;
      case "descone":
        setDescone(value);
        break;
      case "userreviewheading":
        setUserReviewHeading(value);
        break;
      case "userreviewposition":
        setUserReviewPosition(value);
        break;
      case "headingthree":
        setHeadingThree(value);
        break;
      case "ctatitleheading":
        setCtaTitleHeading(value);
        break;
      case "ctadesc":
        setCtaDesc(value);
        break;
      case "ctabutton":
        setCtaButton(value);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    getTeamDetails();
  }, []);

  const getTeamDetails = async () => {
    console.warn(params);
    let result = await fetch(
      `${process.env.REACT_APP_BACKEND}/api/update-homepage/${params.id}`
    );
    result = await result.json();
    setHeadingOne(result.headingone);
    setButtonOne(result.buttonone);
    setHeadingTwo(result.headingtwo);
    setSubheadingTwo(result.subheadingtwo);
    setButtonTwo(result.buttontwo);
    setDescone(result.descone);
    setUserReviewHeading(result.userreviewheading);
    setUserReviewPosition(result.userreviewposition);
    setHeadingThree(result.headingthree);
    setCtaTitleHeading(result.ctatitleheading);
    setCtaDesc(result.ctadesc);
    setCtaButton(result.ctabutton);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    submitProduct(); // Call the submitProduct function here
  };

  const submitProduct = async () => {
    const baseUrl = `${process.env.REACT_APP_BACKEND}/api/update-homepage/${params.id}`;
    const data = {
      headingone,
      buttonone,
      headingtwo,
      subheadingtwo,
      buttontwo,
      descone,
      userreviewheading,
      userreviewposition,
      headingthree,
      ctatitleheading,
      ctadesc,
      ctabutton,
    };
    const result = await request({ url: baseUrl, method: "PUT", body: data });
    if (result) {
      console.log(result);
      navigate("/pages-list");
    }

    // replace with actual submission logic
  };

  return (
    <div className="content-wrapper">
      <div className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1 className="m-0">Update</h1>
            </div>
            {/* /.col */}
            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right"></ol>
            </div>
            {/* /.col */}
          </div>
          {/* /.row */}
        </div>
        {/* /.container-fluid */}
      </div>
      <div className="adminform-background px-3 mx-3 py-3">
        <form onSubmit={handleSubmit}>
          <TextField
            label="Heading One"
            name="headingone"
            value={headingone}
            onChange={handleChange}
            fullWidth
          />
          <TextField
            label="Button One Text"
            name="buttonone"
            value={buttonone}
            onChange={handleChange}
            fullWidth
          />
          <TextField
            label="Heading Two"
            name="headingtwo"
            value={headingtwo}
            onChange={handleChange}
            fullWidth
          />
          <TextField
            label="Subheading Two"
            name="subheadingtwo"
            value={subheadingtwo}
            onChange={handleChange}
            fullWidth
          />
          <TextField
            label="Button Two Text"
            name="buttontwo"
            value={buttontwo}
            onChange={handleChange}
            fullWidth
          />
          <TextField
            label="Description One"
            name="descone"
            value={descone}
            onChange={handleChange}
            fullWidth
            multiline
          />
          <TextField
            label="User Review Heading"
            name="userreviewheading"
            value={userreviewheading}
            onChange={handleChange}
            fullWidth
          />
          <TextField
            label="User Review Position"
            name="userreviewposition"
            value={userreviewposition}
            onChange={handleChange}
            fullWidth
          />
          <TextField
            label="Heading Three"
            name="headingthree"
            value={headingthree}
            onChange={handleChange}
            fullWidth
          />
          <TextField
            label="CTA Title Heading"
            name="ctatitleheading"
            value={ctatitleheading}
            onChange={handleChange}
            fullWidth
          />
          <TextField
            label="CTA Description"
            name="ctadesc"
            value={ctadesc}
            onChange={handleChange}
            fullWidth
            multiline
          />
          <TextField
            label="CTA Button Text"
            name="ctabutton"
            value={ctabutton}
            onChange={handleChange}
            fullWidth
          />
          <Button type="submit" variant="contained" color="primary">
            Update
          </Button>
        </form>
      </div>
    </div>
  );
};

export default UpdateHomepage;
