import { store } from "../store";
import { setUser } from "../store/user";

/**
 * Makes an HTTP request with authentication headers.
 *
 * @param {Object} options - The request options.
 * @param {string} options.url - The URL to make the request to.
 * @param {string} [options.method='GET'] - The HTTP method for the request.
 * @param {Object} [options.headers] - Additional headers for the request.
 * @param {Object} [options.body] - The request body Json.
 * @param {Object} [options.data] - The request body FormData.
 * @returns {Promise<Response>} - A Promise that resolves to the Response to that request.
 */
function request(options) {
  const requestOptions = {
    ...options,
    headers: authHeader(options.url),
  };
  if (options.body) {
    requestOptions.headers["Content-Type"] = "application/json";
    requestOptions.body = JSON.stringify(options.body);
  } else if (options.data) {
    requestOptions.body = options.data;
  }

  return fetch(options.url, requestOptions)
    .then((response) => {
      return response;
    })
    .then(handleResponse)
    .catch((error) => {
      console.error("Request Error:", error);
      throw error;
    });
}

// helper functions

function authHeader() {
  // return auth header with jwt if user is logged in and request is to the api url
  const token = authToken();
  const isLoggedIn = !!token;
  if (isLoggedIn) {
    return { Authorization: `Bearer ${token}` };
  } else {
    return {};
  }
}

function authToken() {
  try {
    const token = localStorage.getItem("token");
    return token || null; // return null if token is undefined
  } catch (err) {
    console.error("Error retrieving token:", err);
    return null;
  }
}

function handleResponse(response) {
  return response.text().then((text) => {
    let data = null;

    try {
      data = JSON.parse(text);
    } catch (err) {
      data = text;
    }
    if (!response.ok) {
      if (response.status == 401) {
        localStorage.removeItem("user");
        localStorage.removeItem("token");
        store.dispatch(setUser(null));
        return;
      }

      const error = data?.message || data || response.statusText;
      return Promise.reject(error);
    }

    return data;
  });
}

export default request;
