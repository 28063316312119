import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import { Link } from 'react-router-dom';
import uicon from '../../assets/JAJ-photo.webp'

const Secondsec = () => {
    const [data, setData] = useState([]);

  const fetchData = async () => {
      const response = await fetch(`${process.env.REACT_APP_BACKEND}/api/get-homepage`);
      const result = await response.json();
      setData(result);
    };
    useEffect(() => {
      fetchData();
    }, []);
  return (
    <section>
        <Container >
        {data.map((row, index) => (

            <Row className='second-sec' >
                
                <Col className='' md={12}>
                <h2 className='second-sec-title text-center'>{row.headingtwo}</h2>
                <p className='text-center'>{row.subheadingtwo}</p><br/>
                <div className="d-flex justify-content-center align-items-center">
  <Link to={row.buttontwo}>
    <Button variant="outline-light" className="blue-btn text-decoration-none">Explore Joot's Services</Button>
  </Link>
</div>

                </Col>
                {/* <Col md={5}>
                    <div className='second-box'>
                        <p>
                            {row.descone}
                        </p>
                        <div className='user-white-box'>
                        <Row>
                            <Col md={2}>
                                <img src={uicon}  className='uicon'/>
                            </Col>
                            <Col md={10}>
                                <h3>{row.userreviewheading}</h3>
                                <p className='user-position'>{row.userreviewposition}</p>
                            </Col>
                        </Row>
                        </div>
                    </div>
                        
                </Col>  */}
            </Row>
        ))}
        </Container>
    </section>
  )
}

export default Secondsec