import React,{useEffect,useState} from 'react'
import { Container, Row , Col} from 'react-bootstrap'
import img from '../../assets/3.PNG';
import Button from 'react-bootstrap/Button';
import ReactHtmlParser from 'react-html-parser';


const Thirdsec = () => {
    const [partner, setpartner] = useState([]);

    const fetchpartnerpage = async () => {
        const response = await fetch(`${process.env.REACT_APP_BACKEND}/api/get-mocksecexam`);
        const result = await response.json();
        setpartner(result);
      };
      useEffect(() => {
          fetchpartnerpage();
      }, []);
  return (

    <section className='refunds-service-sec-3'>
       <Container >
                <Row>
                    <Col md={7} className="py-5">
                    {partner.map((row, index) => (

<>
<h1>{row.thirdheading}</h1>
<p>
{ReactHtmlParser(row.thirddesc)}

</p>
{/* <Button variant="outline-light my-5" className="blue-btn ">GET FUNDS COMPLIANCE HELP</Button> */}
</>
))}
                        {/* <Button variant="outline-light my-5" className="blue-btn ">GET FUNDS COMPLIANCE HELP</Button> */}

                    </Col>
                    <Col md={5}>
                        <img className='about-intro-img' src={img}/>
                    </Col>
                </Row>
            </Container>
    </section>

    )
}

export default Thirdsec