import React, { useState, useEffect } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
} from '@material-ui/core';
import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';

import ModeEditOutlineTwoToneIcon from '@mui/icons-material/ModeEditOutlineTwoTone';

import Button from 'react-bootstrap/Button';
import { Link } from 'react-router-dom';
import '../../index.css'



const Data = () => {
  const [data, setData] = useState([]);
  const [services, setServices] = useState([]);
  const [rsfunds, setrsfunds] = useState([]);
  const [mock, setMock] = useState([]);
  const [ria, setria] = useState([]);
  const [cnewsletter, setcnewsletter] = useState([]);
  const [events, setEvents] = useState([]);
  const [resources, setResources] = useState([]);
  const [abouts, setAbouts] = useState([]);
  const [partners, setPartners] = useState([]);
  const [contacts, setContacts] = useState([]);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  useEffect(() => {
    fetchrsfunds();
    fetchria();
    fetchmock();
    fetchservices();
    fetchData();
    fetchsnewsletter();
    fetchsevents();
    fetchesources();
    fetchabouts();
    fetchpartnerspage();
    fetchcontactpage();
  }, []);

  const fetchcontactpage = async () => {
    const response = await fetch(`${process.env.REACT_APP_BACKEND}/api/get-contactpage`);
    const result = await response.json();
    setContacts(result);
  }; 
  const fetchpartnerspage = async () => {
    const response = await fetch(`${process.env.REACT_APP_BACKEND}/api/get-partnerpage`);
    const result = await response.json();
    setPartners(result);
  }; 

  const fetchabouts = async () => {
    const response = await fetch(`${process.env.REACT_APP_BACKEND}/api/get-aboutpage`);
    const result = await response.json();
    setAbouts(result);
  }; 

  const fetchesources = async () => {
    const response = await fetch(`${process.env.REACT_APP_BACKEND}/api/get-reourcespage`);
    const result = await response.json();
    setResources(result);
  }; 


  const fetchsevents = async () => {
    const response = await fetch(`${process.env.REACT_APP_BACKEND}/api/get-eventspage`);
    const result = await response.json();
    setEvents(result);
  }; 

  const fetchsnewsletter = async () => {
    const response = await fetch(`${process.env.REACT_APP_BACKEND}/api/get-compliancenewsletterpage`);
    const result = await response.json();
    setcnewsletter(result);
  }; 
  const fetchrsfunds = async () => {
    const response = await fetch(`${process.env.REACT_APP_BACKEND}/api/get-registeredfunds`);
    const result = await response.json();
    setrsfunds(result);
  }; 
  const fetchria = async () => {
    const response = await fetch(`${process.env.REACT_APP_BACKEND}/api/get-riaregistrationservices`);
    const result = await response.json();
    setria(result);
  }; 
 

  const fetchmock = async () => {
    const response = await fetch(`${process.env.REACT_APP_BACKEND}/api/get-mocksecexam`);
    const result = await response.json();
    setMock(result);
  }; 
 
  const fetchservices = async () => {
    const response = await fetch(`${process.env.REACT_APP_BACKEND}/api/get-servicespage`);
    const result = await response.json();
    setServices(result);
  }; 
  

  const fetchData = async () => {
    const response = await fetch(`${process.env.REACT_APP_BACKEND}/api/get-homepage`);
    const result = await response.json();
    setData(result);
  };
  

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
 
  return (
    <div className='content-wrapper'>
      <div className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1 className="m-0"> Pages</h1>
            </div>{/* /.col */}
            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
              <Link to='/new-homepage'>
              <Button variant="primary" size='lg'>Add New</Button>
              </Link>
              </ol>
            </div>{/* /.col */}
          </div>{/* /.row */}
        </div>{/* /.container-fluid */}
      </div>
      <div className='mx-3'>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell> Name</TableCell>
              
              <TableCell>Actions</TableCell>

            </TableRow>
          </TableHead>
          <TableBody>
          {data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => (
            
              <TableRow key={row._id}>
                <TableCell><b>Homepage</b></TableCell>
                

                <TableCell><Link to={"/update-homepage/"+row._id}><ModeEditOutlineTwoToneIcon></ModeEditOutlineTwoToneIcon></Link>
                </TableCell>
                
              </TableRow>
            ))}
            {services.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => (
            
            <TableRow key={row._id}>
              <TableCell><b>Services</b></TableCell>
              

              <TableCell><Link to={"/update-servicespage/"+row._id}><ModeEditOutlineTwoToneIcon></ModeEditOutlineTwoToneIcon></Link>
              </TableCell>
              
            </TableRow>
          ))}
          {rsfunds.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => (
            
            <TableRow key={row._id}>
              <TableCell><b>Registered Funds</b></TableCell>
              

              <TableCell><Link to={"/update-registeredfunds/"+row._id}><ModeEditOutlineTwoToneIcon></ModeEditOutlineTwoToneIcon></Link>
              </TableCell>
              
            </TableRow>
          ))}

          {mock.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => (
            
            <TableRow key={row._id}>
              <TableCell><b>Mock SEC Exam</b></TableCell>
              

              <TableCell><Link to={"/update-mocksecexam/"+row._id}><ModeEditOutlineTwoToneIcon></ModeEditOutlineTwoToneIcon></Link>
              </TableCell>
              
            </TableRow>
          ))}
          
          {ria.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => (
            
            <TableRow key={row._id}>
              <TableCell><b>RIA Registration Services</b></TableCell>
              

              <TableCell><Link to={"/update-riaregistrationservices/"+row._id}><ModeEditOutlineTwoToneIcon></ModeEditOutlineTwoToneIcon></Link>
              </TableCell>
              
            </TableRow>
          ))}


          {resources.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => (
            
            <TableRow key={row._id}>
              <TableCell><b>Resources</b></TableCell>
              

              <TableCell><Link to={"/update-reourcespage/"+row._id}><ModeEditOutlineTwoToneIcon></ModeEditOutlineTwoToneIcon></Link>
              </TableCell>
              
            </TableRow>
          ))}

          {cnewsletter.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => (
            
            <TableRow key={row._id}>
              <TableCell><b>Compliance Newsletter</b></TableCell>
              

              <TableCell><Link to={"/update-compliancenewsletterpage/"+row._id}><ModeEditOutlineTwoToneIcon></ModeEditOutlineTwoToneIcon></Link>
              </TableCell>
              
            </TableRow>
          ))}
            

            {events.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => (
            
            <TableRow key={row._id}>
              <TableCell><b>Events</b></TableCell>
              

              <TableCell><Link to={"/update-eventspage/"+row._id}><ModeEditOutlineTwoToneIcon></ModeEditOutlineTwoToneIcon></Link>
              </TableCell>
              
            </TableRow>
          ))}
            

            
            {abouts.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => (
            
            <TableRow key={row._id}>
              <TableCell><b>About</b></TableCell>
              

              <TableCell><Link to={"/update-aboutpage/"+row._id}><ModeEditOutlineTwoToneIcon></ModeEditOutlineTwoToneIcon></Link>
              </TableCell>
              
            </TableRow>
          ))}

            {partners.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => (
            
            <TableRow key={row._id}>
              <TableCell><b>Partners</b></TableCell>
              

              <TableCell><Link to={"/update-partnerpage/"+row._id}><ModeEditOutlineTwoToneIcon></ModeEditOutlineTwoToneIcon></Link>
              </TableCell>
              
            </TableRow>
          ))}
            
            {contacts.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => (
            
            <TableRow key={row._id}>
              <TableCell><b>Contact</b></TableCell>
              

              <TableCell><Link to={"/update-contactpage/"+row._id}><ModeEditOutlineTwoToneIcon></ModeEditOutlineTwoToneIcon></Link>
              </TableCell>
              
            </TableRow>
          ))}
            
          </TableBody>
        </Table>
        {/* <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={data.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        /> */}
      </TableContainer>
      </div>
    </div>
  );
};

export default Data;
