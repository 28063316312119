import React, { useState, useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Card from "react-bootstrap/Card";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLinkedin, faTwitter } from "@fortawesome/free-brands-svg-icons";
import { Link } from "react-router-dom";

const Secondsec = () => {
  const [data, setData] = useState([]);

  const fetchData = async () => {
    const response = await fetch(
      `${process.env.REACT_APP_BACKEND}/api/get-team-with-order`
    );
    const result = await response.json();
    setData(result);
  };

  useEffect(() => {
    fetchData();
  }, []);

  // Filter team members where displayOnTeamPage is 'yes'
  const filteredData = data.filter((row) => {
    return row.displayOnTeamPage === "yes";
  });

  return (
    <section className="meet-team-sec">
      <Container>
        <Row>
          <h1 className="py-5">Team Members</h1>
          {filteredData.map((row, index) => (
            <Col md={4} key={index}>
              <Card className="team-card">
                <Card.Img
                  className="team-img"
                  variant="top"
                  src={
                    "https://storage.googleapis.com/fintechlegal/joot_posts/" +
                    row.document
                  }
                />
                <Card.Body className="team-body">
                  <Card.Title>{row.membername}</Card.Title>
                  <p className="team-sec-position">{row.memberposition}</p>
                  <Card.Text className="team-desc">{row.memberdesc}</Card.Text>
                  <Row>
                    <Col>
                      {row.memberlinkden && (
                        <Link to={row.memberlinkden}>
                          <FontAwesomeIcon
                            icon={faLinkedin}
                            size="2x"
                            className="px-2"
                          />
                        </Link>
                      )}
                      {row.membertwitter && (
                        <Link to={row.membertwitter}>
                          <FontAwesomeIcon
                            icon={faTwitter}
                            size="2x"
                            className="px-2"
                          />
                        </Link>
                      )}
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
      </Container>
    </section>
  );
};

export default Secondsec;
