import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Editor } from "@tinymce/tinymce-react";
import {
  TextField,
  Button,
  Grid,
  makeStyles,
  InputLabel,
  Select,
  MenuItem,
} from "@material-ui/core";
import "../../index.css";
import TagsInput from "react-tagsinput";
import "react-tagsinput/react-tagsinput.css";
import request from "../../../utils/http-client";
import { useSelector } from "react-redux";

const MAX_CHUNK_LENGTH = 1000000;

const splitStringIntoChunks = (str, chunkLength) => {
  const chunks = [];
  for (let i = 0; i < str.length; i += chunkLength) {
    chunks.push(str.slice(i, i + chunkLength));
  }
  return chunks;
};

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      width: "100%",
    },
  },
}));

const UpdateBlog = () => {
  const classes = useStyles();
  const currentUser = useSelector((state) => state.user.info);
  const [posttitle, setName] = useState("");
  const [description, setDesc] = useState("");
  const [excerpt, setExcerpt] = useState("");
  const [metatitle, setMetatitle] = useState("");
  const [metadescription, setMetadescription] = useState("");
  const [file, setFile] = useState();
  const [selectedTeam, setSelectedTeam] = useState("");
  const [fileName, setFileName] = useState("");
  const [error, SetError] = useState("");
  const [tags, setTags] = useState([]);
  const [teams, setTeams] = useState([]);
  const [slug, setSlug] = useState("");
  const params = useParams();
  const navigate = useNavigate();
  const handleNameChange = (event) => {
    setName(event.target.value);
  };
  const handleSlugChange = (event) => {
    setSlug(event.target.value);
  };
  const handleExcerptChange = (event) => {
    setExcerpt(event.target.value);
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    submitProduct(); // Call the submitProduct function here
  };

  const fetchTeam = async () => {
    const response = await fetch(
      `${process.env.REACT_APP_BACKEND}/api/get-team-with-order`
    );
    const result = await response.json();
    setTeams(result);
  };

  useEffect(() => {
    getTeamDetails();
  }, []);

  const getTeamDetails = async () => {
    await fetchTeam();
    let result = await fetch(
      `${process.env.REACT_APP_BACKEND}/api/update-blog/${params.slug}`
    );
    result = await result.json();
    setName(result.posttitle);
    setDesc(result.description);
    setExcerpt(result.excerpt);
    setMetatitle(result.metatitle);
    setMetadescription(result.metadescription);
    setSlug(result.slug);
    if (result.selectedTeam) {
      setSelectedTeam(result.selectedTeam._id);
    }
    if (result.tags) {
      setTags(result.tags.split(","));
    }
    setFileName(result.document);
  };

  const submitProduct = async () => {
    const userID = currentUser._id;
    const stringChunks = splitStringIntoChunks(description, MAX_CHUNK_LENGTH);
    const baseUrl = `${process.env.REACT_APP_BACKEND}/api/update-post/${params.slug}`;
    const data = {
      posttitle: posttitle,
      excerpt: excerpt,
      tags: tags,
      slug: slug,
      metatitle: metatitle,
      metadescription: metadescription,
      userID: userID,
      selectedTeam,
    };
    const formData = new FormData();
    for (let name in data) {
      formData.append(name, data[name]);
    }
    formData.append("numOfDescChunks", stringChunks.length);
    stringChunks.forEach((chunk, i) => {
      formData.append(`description_${i}`, chunk);
    });
    formData.append("file", file);
    const result = await request({
      method: "put",
      url: baseUrl,
      data: formData,
    });
    if (result) {
      navigate("/post-list");
    }
  };

  const handleChange = (event) => {
    setSelectedTeam(event.target.value);
  };

  const saveFile = (e) => {
    setFile(e.target.files[0]);
    setFileName(e.target.files[0].name);
  };

  const handleTagChange = (tags) => {
    setTags(tags);
  };

  return (
    <>
      <div className="content-wrapper">
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1 className="m-0">Add New Post</h1>
              </div>
              {/* /.col */}
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right"></ol>
              </div>
              {/* /.col */}
            </div>
            {/* /.row */}
          </div>
          {/* /.container-fluid */}
        </div>

        <div className="adminform-background px-3 mx-3 py-3">
          <form className={classes.root} onSubmit={handleSubmit}>
            <Grid container spacing={4}>
              <Grid item xs={12}>
                <TextField
                  required
                  label="Post Title"
                  value={posttitle}
                  onChange={handleNameChange}
                />
                <TextField
                  required
                  label="Post slug"
                  value={slug}
                  onChange={handleSlugChange}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  label="Post Excerpt"
                  value={excerpt}
                  onChange={handleExcerptChange}
                />
              </Grid>
              <Grid item xs={12}>
                <InputLabel htmlFor="name-input">Post Description</InputLabel>

                <Editor
                  apiKey={process.env.REACT_APP_TINYMCE_KEY}
                  textareaName="product Description"
                  value={description}
                  //onEditorChange={(e)=>SetDescription(e.target.value)}
                  onEditorChange={(newValue, editor) => setDesc(newValue)}
                  init={{
                    height: 500,
                    menubar: true,
                    plugins:
                      "autolink lists link image charmap print preview anchor searchreplace visualblocks code fullscreen insertdatetime media table paste code help wordcount",

                    toolbar:
                      "undo redo | formatselect | " +
                      "bold italic backcolor | alignleft aligncenter " +
                      "alignright alignjustify | bullist numlist outdent indent | " +
                      "removeformat | help",
                    content_style:
                      "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                  }}
                />
              </Grid>

              <Grid item xs={6}>
                <InputLabel htmlFor="name-input">Add Seo Tags</InputLabel>

                <TagsInput value={tags} onChange={handleTagChange} />
              </Grid>
              <Grid item xs={6}>
                <input
                  accept="image/*"
                  id="thumbnail-upload"
                  type="file"
                  onChange={saveFile}
                  style={{ display: "none" }}
                />
                {/* {fileName && (
            <div className='img-box-dashboard-featured'>
            <img
              src={URL.createObjectURL(file)}
              alt="Selected file preview"
              
            />
            </div>
          )} */}
                <label htmlFor="thumbnail-upload">
                  <Button variant="contained" color="primary" component="span">
                    Upload Resource
                  </Button>
                </label>
              </Grid>
              <Grid item xs={6}>
                <TextField
                  required
                  label="Meta Title"
                  value={metatitle}
                  onChange={(e) => setMetatitle(e.target.value)}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  required
                  label="Meta Description"
                  value={metadescription}
                  onChange={(e) => setMetadescription(e.target.value)}
                />
              </Grid>
              <Grid item xs={6}>
                <InputLabel id="team-select-label">Select Author</InputLabel>
                <Select
                  labelId="team-select-label"
                  id="team-select"
                  value={selectedTeam}
                  onChange={handleChange}
                  style={{ width: "100%" }}
                >
                  {teams.map((team) => (
                    <MenuItem key={team._id} value={team._id}>
                      <img
                        className="table-backend-img-author"
                        src={
                          "https://storage.googleapis.com/fintechlegal/joot_posts/" +
                          team.document
                        }
                      />
                      - {team.membername}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
              <Grid item xs={12}>
                <Button variant="contained" color="primary" type="submit">
                  Save
                </Button>
              </Grid>
            </Grid>
          </form>
        </div>
      </div>
    </>
  );
};

export default UpdateBlog;
