import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import img from "../../assets/compliance-graphic.png";
import Button from "react-bootstrap/Button";
import "./Home.css";
import { Link } from "react-router-dom";
const Intro = () => {
  const [data, setData] = useState([]);

  const fetchData = async () => {
    const response = await fetch(
      `${process.env.REACT_APP_BACKEND}/api/get-homepage`
    );
    const result = await response.json();
    setData(result);
  };
  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <section className="hero-section introsec">
        <Container>
          <Row className="py-3">
            <Col>
              {data.map((row, index) => (
                <>
                  <h1 className=" intro-title">{row.headingone}</h1>
                  <img className="intro-icon py-3" src={img} />
                  <br />
                  <Link to={row.buttonone} className="text-decoration-none">
                    <Button variant="outline-light" className="white-btn">
                      Explore Joot’s Services
                    </Button>
                  </Link>
                </>
              ))}
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default Intro;
