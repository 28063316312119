import React, { useState, useEffect } from 'react';
import { Button, Col, Container, Row } from 'react-bootstrap'
import './newsletter.css'
const SecondrySec = () => {

    const [data, setData] = useState([]);

    const fetchData = async () => {
        const response = await fetch(`${process.env.REACT_APP_BACKEND}/api/get-newsletterresources`);
        const result = await response.json();
        setData(result);
    };
    useEffect(() => {
        fetchData();
    }, []);

    
    const styles ={
        textAlign  : 'center',
        margin : '0 uato'
    }

  return (
    <section>
      <Container>
        <Row className="py-5">
          {data
            .slice(0)
            .reverse()
            .map((row, index) => (
              <Col md={6} style={styles}>
                <div class="styles-module--download--d821d">
                  <a
                    href={`${process.env.REACT_APP_BACKEND}/api/download-newsletter/${row.fileId}`}
                    target="_blank"
                    rel="noopener"
                    class="styles-module--link--2993b"
                  >
                    <div class="styles-module--downloadContainer--b7b2b">
                      <div>
                        <h3>{row.name}</h3>
                      </div>
                      <div>
                        <a
                          href={`${process.env.REACT_APP_BACKEND}/api/download-newsletter/${row.fileId}`}
                          target="_blank"
                          rel="noopener"
                          class="styles-module--link--2993b styles-module--container--668a0 styles-module--small--d5a40 styles-module--auto--693af  styles-module--buttonDownload--b6ed2"
                        >
                          Download
                        </a>
                      </div>
                    </div>
                  </a>
                </div>
              </Col>
            ))}
        </Row>
      </Container>
    </section>
  );
}

export default SecondrySec