import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Card, Button, Modal } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import ReactHtmlParser from 'react-html-parser';
import Modelimg1 from '../../assets/modelimg.png';
import Modelimg2 from '../../assets/modelimg2.png';
import { TextField } from '@mui/material';

const Secondsec = () => {
  const [data, setData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedResource, setSelectedResource] = useState(null);
  const [userName, setUserName] = useState('');
  const [userEmail, setUserEmail] = useState('');

  const fetchData = async () => {
    const response = await fetch(
      `${process.env.REACT_APP_BACKEND}/api/get-additionalresources`
    );
    const result = await response.json();

    // Set the index for each resource
    const resourcesWithIndex = result.map((resource, index) => ({
      ...resource,
      index,
    }));

    setData(resourcesWithIndex);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const openModal = (resource) => {
    setSelectedResource(resource);
    setShowModal(true);
  };

  const closeModal = () => {
    setSelectedResource(null);
    setShowModal(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Perform any validation here
    // Download the guide or perform any other action
    // ...
    // Close the modal after submission
    closeModal();
  };

  return (
    <section className="resources-second-sec">
      <Container>
        <Row className="py-5">
          <Col>
            <h1>Additional Resources & Downloads</h1>
          </Col>
        </Row>
        <Row className="py-5 justify-content-center">
          {data.map((row, index) => (
            <Col md={4} key={index}>
              <Card>
                <Card.Header className="pink-card">
                  <span className="serv">{row.additionalresourcestitle}</span>
                </Card.Header>
                <Card.Body>
                  <Card.Text className="c-p">
                    {ReactHtmlParser(row.additionalresourcesdesc)}
                  </Card.Text>
                  <Link onClick={() => openModal(row)}>
                    <Button variant="primary">DOWNLOAD GUIDE</Button>
                  </Link>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
      </Container>

      {/* Modal */}
      <Modal
        show={showModal}
        onHide={closeModal}
        dialogClassName="custom-modal-dialog"
      >
        <Modal.Header closeButton>
          <Modal.Title>Download Guide</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container>
            <Row>
              {/* First Column */}
              <Col
                md={6}
                style={{ backgroundColor: "#d40f67" }}
                className="d-flex justify-content-center align-items-center"
              >
                {selectedResource && selectedResource.index === 0 ? (
                  <img className="modelimg" src={Modelimg1} alt="Model 1" />
                ) : (
                  <img className="modelimg" src={Modelimg2} alt="Model 2" />
                )}
              </Col>

              {/* Second Column */}
              <Col md={6}>
                <div>
                  {selectedResource && (
                    <>
                      <h2 className="text-center">
                        {selectedResource.additionalresourcestitle}
                      </h2>
                      <p className="text-center">
                        {ReactHtmlParser(
                          selectedResource.additionalresourcesdesc
                        )}
                      </p>
                    </>
                  )}
                </div>
                {selectedResource && (
                  <form onSubmit={handleSubmit}>
                    {selectedResource.index === 0 ? (
                      <iframe
                        width="100%"
                        height="450px"
                        src="https://forms.zohopublic.com/codyfoster/form/ResourceDownloadUltimateMarketingGuide/formperma/AYGpEWX5ODVEaVyPnwIRTAX2OHuG8S3Vi_HjvIw2Asg?zf_rszfm=1"
                      />
                    ) : (
                      <iframe
                        width="100%"
                        height="450px"
                        src="https://forms.zohopublic.com/codyfoster/form/DownloadJootsGuidetoSurvivingaRegulatoryExaminatio/formperma/ewSbLzVjyyrNcYdfs_SSjojNtXTtnahh0lFPrYfbnrM?zf_rszfm=1"
                      />
                    )}
                    <Row className="py-5"></Row>
                  </form>
                )}
              </Col>
            </Row>
          </Container>
        </Modal.Body>
      </Modal>
    </section>
  );
};

export default Secondsec;
