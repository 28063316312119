import React ,{useState,useEffect}from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import Button from 'react-bootstrap/Button';
import { Link } from 'react-router-dom';
import './ReFunds.css'

const Intro = () => {
    const [partner, setpartner] = useState([]);

    const fetchpartnerpage = async () => {
        const response = await fetch(`${process.env.REACT_APP_BACKEND}/api/get-registeredfunds`);
        const result = await response.json();
        setpartner(result);
      };
      useEffect(() => {
          fetchpartnerpage();
      }, []);
  return (
        <section className='refunds-service-sec-1'>
            <Container>
                <Row>
                    <Col>
                    {partner.map((row, index) => (

<>
    <h2 >{row.subheading}</h2>
    <h1>{row.heading}</h1>
    <p>{row.desc}
        </p>
    <Button variant="outline-light my-5" className="blue-btn refundbtn">        <Link className='text-decoration-none text-white' to={row.buttonone}>
GET COMPLIANCE DONE RIGHT</Link></Button>
</>
))}
                    </Col>
                </Row>
            </Container>
        </section>

    )
}

export default Intro