import React from 'react'
import Header from '../../Components/Header/Header'
import Secondsec from './Secondsec'
import Intro from './Intro'
import Thirdsec from './Thirdsec'
import Footer from '../../Components/Footer/Footer'
import FourthSec from './FourthSec'
import { Helmet } from 'react-helmet'

const index = () => {
  return (
    <>
    <Helmet>
        <title>
        Compliance Events & Webinars | Joot Events
        </title>
        <meta name="description" content="View current and past compliance events and webinars hosted or attended by members of Joot's financial compliance team.
"/>
    </Helmet>
        <Header/>
            <React.Fragment>
                <Intro/>
                <Secondsec/>
                <Thirdsec/>
                <FourthSec/>
            </React.Fragment>
        <Footer/>
    
    
    </>

    )
}

export default index