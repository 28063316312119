import React from 'react';
import {Helmet} from "react-helmet";
import Footer from '../../Components/Footer/Footer';
import Header from '../../Components/Header/Header';
import Intro from './Intro';
import Secondsec from './Secondsec';
import Fourthsec from './Fourthsec';
import ThirdSec from './ThirdSec';

const Services = () => {
  return (
    <>
      <Helmet>
        <title>RIA Compliance Services | Joot SEC Compliance</title>
        <meta name="description" content="Joot offers RIA compliance services to investment advisers including mock SEC exams, outsourced CCO services, SEC registration, and more." />
      </Helmet>
      <Header />
      <React.Fragment>
        <Intro />
        <Secondsec />
        <ThirdSec />
        <Fourthsec />
      </React.Fragment>
      <Footer />
    </>
  );
};

export default Services;
