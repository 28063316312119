import React, { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Editor } from "@tinymce/tinymce-react";
import {
  TextField,
  Button,
  Grid,
  makeStyles,
  InputLabel,
} from "@material-ui/core";

import request from "../../../utils/http-client";

const UpdateOutsource = () => {
  const [outsourcenumber, setOutsourcenumber] = useState("");
  const [outsourcetitle, setOutsourcetitle] = useState("");
  const [outsourcedesc, setOutsourcedesc] = useState("");
  const params = useParams();

  const navigate = useNavigate();

  const handleSubmit = (event) => {
    event.preventDefault();
    submitProduct(); // Call the submitProduct function here
  };

  useEffect(() => {
    getTeamDetails();
  }, []);
  const getTeamDetails = async () => {
    console.warn(params);
    let result = await fetch(
      `${process.env.REACT_APP_BACKEND}/api/update-outsource/${params.id}`
    );
    result = await result.json();
    setOutsourcenumber(result.outsourcenumber);
    setOutsourcetitle(result.outsourcetitle);
    setOutsourcedesc(result.outsourcedesc);
  };

  const submitProduct = async () => {
    const baseUrl = `${process.env.REACT_APP_BACKEND}/api/update-outsource/${params.id}`;
    const data = {
      outsourcenumber,
      outsourcetitle,
      outsourcedesc,
    };
    const result = await request({ url: baseUrl, method: "PUT", body: data });
    if (result) {
      navigate("/outsource-data");
    }

    // replace with actual submission logic
  };

  return (
    <div className="content-wrapper">
      <div className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1 className="m-0">Add New OutSources</h1>
            </div>
            {/* /.col */}
            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right"></ol>
            </div>
            {/* /.col */}
          </div>
          {/* /.row */}
        </div>
        {/* /.container-fluid */}
      </div>
      <div className="adminform-background px-3 mx-3 py-3">
        <form onSubmit={handleSubmit}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextField
                required
                label="Outsource Number"
                value={outsourcenumber}
                onChange={(event) => setOutsourcenumber(event.target.value)}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                label="OutSource Title"
                value={outsourcetitle}
                onChange={(event) => setOutsourcetitle(event.target.value)}
                fullWidth
              />
            </Grid>

            <Grid item xs={12}>
              <InputLabel htmlFor="name-input">Description</InputLabel>

              <Editor
                apiKey={process.env.REACT_APP_TINYMCE_KEY}
                textareaName="product Description"
                value={outsourcedesc}
                //onEditorChange={(e)=>SetDescription(e.target.value)}
                onEditorChange={(newValue, editor) =>
                  setOutsourcedesc(newValue)
                }
                init={{
                  height: 500,
                  menubar: true,
                  plugins:
                    "autolink lists link image charmap print preview anchor searchreplace visualblocks code fullscreen insertdatetime media table paste code help wordcount",

                  toolbar:
                    "undo redo | formatselect | " +
                    "bold italic backcolor | alignleft aligncenter " +
                    "alignright alignjustify | bullist numlist outdent indent | " +
                    "removeformat | help",
                  content_style:
                    "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                }}
              />
            </Grid>

            <Grid item xs={12}>
              <Button type="submit" variant="contained" color="primary">
                Submit
              </Button>
            </Grid>
          </Grid>
        </form>
      </div>
    </div>
  );
};

export default UpdateOutsource;
