import React from 'react'
import Footer from '../../Components/Footer/Footer'
import Header from '../../Components/Header/Header'
import Intro from './Intro'
import Secondsec from './Secondsec'
import Thirdsec from './Thirdsec'
import { Helmet } from 'react-helmet'

const index = () => {
  return (
    <>
    <Helmet>
        <title>
        Meet the Team | Joot Compliance Team
        </title>
        <meta name="description" content="Joot's compliance team is filled with financial compliance and legal industry veterans with dozens of years of experience.
"/>
    </Helmet>
    <Header/>
        <React.Fragment>
            <Intro/>
            <Secondsec/>
            <Thirdsec/>
        </React.Fragment>
        <Footer/>
        </>
    )
}

export default index