import React,{useEffect,useState} from 'react'
import { Container,Row , Col } from 'react-bootstrap'
import Card from 'react-bootstrap/Card';
import ReactHtmlParser from 'react-html-parser';

const Secondsec = () => {
    const [partner, setpartner] = useState([]);
    const [data, setData] = useState([]);

    const fetchpartnerpage = async () => {
        const response = await fetch(`${process.env.REACT_APP_BACKEND}/api/get-servicespage`);
        const result = await response.json();
        setpartner(result);
      };
      useEffect(() => {
          fetchpartnerpage();
      }, []);

      const fetchData = async () => {
        const response = await fetch(`${process.env.REACT_APP_BACKEND}/api/get-packages`);
        const result = await response.json();
        setData(result);
      };
      useEffect(() => {
        fetchData();
      }, []);
    
  return (
    <section className="service-second-sec">
      <Container>
        <Row>
          <Col>
            {partner.map((row, index) => (
              <>
                <h1 key={index}>{row.secondheading}</h1>
                <p className="py-4">{row.secondsubheading}</p>
              </>
            ))}
          </Col>
        </Row>
        <Row>
          {data.map((row, index) => (
            <Col md={3} key={index}>
              <Card>
                <Card.Header className="first-card">
                  {" "}
                  <span className="serv">{row.packagetitle}</span>
                </Card.Header>
                <Card.Body>
                  {/* <Card.Title className='c-t'>Get Compliance Expertise</Card.Title> */}
                  <Card.Text className="c-p">
                    {ReactHtmlParser(row.packagedesc)}
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
      </Container>
    </section>
  );
}

export default Secondsec