import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import { Link } from "react-router-dom";
import "./Home.css";
import { getPosts } from "../../utils/api";
import { useQuery } from "react-query";

const Blogsec = () => {
  const { data } = useQuery("posts", getPosts);

  if (!data || data.length === 0) {
    return null; // Handle loading or empty data state
  }

  return (
    <section className="blog-sec">
      <Container>
        <Row className="py-3">
          <Col>
            <h1>Recent Posts</h1>
          </Col>
          <Col>
            <Link to="/blog">
              <Button variant="outline-light" className="blue-btn">
                READ THE JOOT BLOG
              </Button>
            </Link>
          </Col>
        </Row>

        <Row>
          {data.slice(0, 2).map((row, index) => (
            <Col md={6} className="blog1stcol" key={index}>
              <Link to={`/blog/${row.slug}`} className="blog-link">
                <Card className="blog-box">
                  <Card.Img
                    variant="top"
                    src={
                      "https://storage.googleapis.com/fintechlegal/joot_posts/" +
                      row.document
                    }
                  />
                  <Card.Body className="blog-body">
                    <p className="blogpage-date">
                      {new Date(row.date).toLocaleDateString()}
                    </p>
                    <Card.Title className="blog-title">
                      {row.posttitle}
                    </Card.Title>
                    <Card.Text className="blog-excerpt">
                      {row.excerpt}
                    </Card.Text>
                    <Row>
                      <Col md={1}>
                        <img
                          className="table-backend-img-author"
                          src={
                            "https://storage.googleapis.com/fintechlegal/joot_posts/" +
                            row.selectedTeam.document
                          }
                          alt="Author"
                        />
                      </Col>
                      <Col md={11}>
                        {row.selectedTeam ? (
                          <>
                            <h6 className="blogpage-author">
                              {row.selectedTeam.membername}
                            </h6>
                            <p className="blogpage-author-position">
                              {row.selectedTeam.memberposition}
                            </p>
                          </>
                        ) : (
                          <>
                            <h6 className="blogpage-author">Author Name</h6>
                            <p className="blogpage-author-position">
                              Author Position
                            </p>
                          </>
                        )}
                      </Col>
                    </Row>
                    <Row>
                      <Col className="blog-tag-col">
                        {row.tags.split(",").map((tag) => (
                          <div key={tag}>
                            <Button
                              variant="outline-light"
                              className="blogtag-btn"
                            >
                              {tag.trim()}
                            </Button>
                          </div>
                        ))}
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              </Link>
            </Col>
          ))}
        </Row>
      </Container>
    </section>
  );
};

export default Blogsec;
