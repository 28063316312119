import React from 'react'
import {Helmet} from "react-helmet";
import Footer from '../../Components/Footer/Footer'
import Header from '../../Components/Header/Header'
import Fourthsec from './Fourthsec'
import Intro from './Intro'
import Secondsec from './Secondsec'
import Thirdsec from './Thirdsec'

const index = () => {
  return (
    <>
    <Helmet>
        <title>Registered Fund Compliance | Joot CCO Services</title>
        <meta name="description" content="Joot's registered fund compliance services offer comprehensive outsource CCO services for funds such as mutual funds, interval funds, and ETFs.
" />
      </Helmet>
        <Header/>
        <React.Fragment>
            <Intro/>
            <Secondsec/>
            <Thirdsec/>
            <Fourthsec/>
        </React.Fragment>
        <Footer/>
        </>
    )
}

export default index