import React, { useState,useEffect,useRef } from 'react';
import { Link , useNavigate} from "react-router-dom";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCoffee , faFaceMehBlank } from '@fortawesome/free-solid-svg-icons';
import { faFacebook, faLinkedin , faTwitter , faYoutube } from '@fortawesome/free-brands-svg-icons';
import { Form, Button } from "react-bootstrap";

import "./Footer.css";
import request from "../../utils/http-client";

const Footer = () => {
  const [email, setEmail] = useState("");
  const navigate = useNavigate();

  const handleSubmit = async () => {
    const baseUrl = `${process.env.REACT_APP_BACKEND}/api/newsletter`;
    const data = {
      email: email,
    };

    const result = await request({ url: baseUrl, body: data, method: "POST" });
    if (result) {
      console.log(result);
      navigate("/");
    }
  };
  return (
    <section className="footer">
      <Container className="">
        <Row className="py-5">
          <Col>
            <Row>
              <Col>
                <Card border="light footer-card">
                  <Card.Header>
                    <Card.Title>Services</Card.Title>
                  </Card.Header>
                  <Card.Body>
                    <Card.Text>
                      <Link className="nounderline" to={"/services"}>
                        Investment Advisers
                      </Link>
                      <br />

                      <Link
                        className="nounderline"
                        to={"/services/registered-fund-services"}
                      >
                        Registered Funds
                      </Link>
                      <br />
                      <Link
                        className="nounderline"
                        to={"/services/mock-sec-exam-services"}
                      >
                        {" "}
                        Mock SEC Exam
                      </Link>
                      <br />
                      <Link
                        className="nounderline"
                        to={"/services/ria-registration-services"}
                      >
                        {" "}
                        RIA Registration
                      </Link>
                      <br />
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
              <Col>
                <Card border="light footer-card">
                  <Card.Header>
                    <Card.Title>
                      <Link className="nounderline" to={"/resources"}>
                        Resources
                      </Link>
                    </Card.Title>
                  </Card.Header>
                  <Card.Body>
                    <Card.Text>
                      <Link className="nounderline" to={"/blog"}>
                        Blog
                      </Link>
                      <br />
                      <Link
                        className="nounderline"
                        to={"/resources/compliance-newsletter"}
                      >
                        {" "}
                        Newsletter
                      </Link>
                      <br />
                      <Link className="nounderline" to={"/events"}>
                        {" "}
                        Events
                      </Link>
                      <br />
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
              <Col>
                <Card border="light footer-card">
                  <Card.Header>
                    <Card.Title>
                      <Link className="nounderline" to={"/about"}>
                        About
                      </Link>
                    </Card.Title>
                  </Card.Header>
                  <Card.Body>
                    <Card.Text>
                      <Link className="nounderline" to={"/about"}>
                        Meet The Team
                      </Link>
                      <br />
                      <Link className="nounderline" to={"/partnerships"}>
                        {" "}
                        Our Partnerships
                      </Link>
                      <br />
                      <Link className="nounderline" to={"/contact"}>
                        {" "}
                        Contact
                      </Link>
                      <br />
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Col>
          <Col>
            {/* <Card border="light footer-card">
                <Card.Header><Card.Title>NEWSLETTER</Card.Title></Card.Header>
                <Card.Body>
                <Card.Text>
                    <Row>
                        <Col>
                          <Form onSubmit={handleSubmit}>
                            <Form.Group controlId="formEmail">
                                <Form.Label>Email</Form.Label>
                                <Form.Control type="email" placeholder="Enter your email" value={email} onChange={(e) => setEmail(e.target.value)} style={{ height: '50px' }} />
                            </Form.Group>
                            <Button variant="primary" type="submit" className='my-3'>
                                Subscribe
                            </Button>
                          </Form>
                    </Col>
                        <Col>
                        </Col>
                    </Row>
                </Card.Text>
                </Card.Body>
            </Card>   */}
            <iframe
              width="500px"
              height="400px"
              src="https://forms.zohopublic.com/codyfoster/form/Newsletter/formperma/7aqx8Fw82SOcsrzXZCq04IFmxKHE15WTIhT9DnZTrVU?zf_rszfm=1"
            ></iframe>
          </Col>
        </Row>
        <Row className="py-5">
          <Col>
            <Link to={"https://www.facebook.com/jootcompliance"}>
              <FontAwesomeIcon icon={faFacebook} size="2x" className="px-2" />
            </Link>
            <Link to={"https://www.linkedin.com/company/joot-io"}>
              <FontAwesomeIcon icon={faLinkedin} size="2x" className="px-2" />
            </Link>
            <Link to={"https://twitter.com/jootcompliance"}>
              <FontAwesomeIcon icon={faTwitter} size="2x" className="px-2" />
            </Link>
            {/* <Link to={'https://www.youtube.com/@joot2254'}><FontAwesomeIcon icon={faYoutube} size="2x"  className='px-2'/></Link>
             */}
            <br />

            <p className="privacy-policytext"></p>
            {/* <p className='privacy-policytext'>Privacy Policy | Terms Of Service</p> */}
            <p className="privacy-policytext">©2023</p>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Footer