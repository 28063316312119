import React, { useState, useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import "../ReFunds/ReFunds.css";
import Card from "react-bootstrap/Card";
import b1 from "../../assets/b1.webp";
import b2 from "../../assets/b2.webp";
import user from "../../assets/Cody_Foster.webp";
import Button from "react-bootstrap/Button";
import { Link } from "react-router-dom";

const Thirdsec = () => {
  const [data, setData] = useState([]);

  const fetchData = async () => {
    const response = await fetch(
      `${process.env.REACT_APP_BACKEND}/api/get-webinars`
    );
    const result = await response.json();
    setData(result);
  };
  useEffect(() => {
    fetchData();
  }, []);

  return (
    <section className="refunds-service-sec-1">
      <Container>
        <Row>
          <h1 className="py-5">Webinar Video Archive</h1>
          <Col md={2} className="blog1stcol"></Col>
          {data.map((row, index) => (
            <Col md={4} className="blog1stcol">
              <Link to={"/webinar-detail/" + row._id} className="blog-link">
                <Card className="blog-box">
                  <Card.Img
                    variant="top"
                    src={
                      "https://storage.googleapis.com/fintechlegal/joot_posts/" +
                      row.document
                    }
                  />
                  <Card.Body className="blog-body">
                    <Card.Title className="blog-title text-center">
                      {row.eventstitle}
                    </Card.Title>
                    <Card.Text className="blog-exerpt">{row.excerpt}</Card.Text>
                  </Card.Body>
                </Card>
              </Link>
            </Col>
          ))}

          <Col md={2} className="blog2ndcol"></Col>
        </Row>
      </Container>
    </section>
  );
};

export default Thirdsec;
