import React, { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Editor } from "@tinymce/tinymce-react";
import {
  TextField,
  Button,
  Grid,
  makeStyles,
  InputLabel,
} from "@material-ui/core";

import request from "../../../utils/http-client";

const UpdateRegisteredFunds = () => {
  const [subheading, setSubheading] = useState("");
  const [heading, setHeading] = useState("");
  const [desc, setDesc] = useState("");
  const [buttonone, setButtonOne] = useState("");
  const [secondheading, setSecondHeading] = useState("");
  const [desctwo, setDescTwo] = useState("");
  const [thirdheading, setThirdHeading] = useState("");
  const [thirddesc, setThirdDesc] = useState("");
  const [thirdbutton, setThirdButton] = useState("");
  const [ctatitleheading, setCtaTitleHeading] = useState("");
  const [ctasubheading, setCtaSubheading] = useState("");
  const [ctabutton, setCtaButton] = useState("");
  const navigate = useNavigate();
  const params = useParams();

  useEffect(() => {
    getTeamDetails();
  }, []);

  const getTeamDetails = async () => {
    console.warn(params);
    let result = await fetch(
      `${process.env.REACT_APP_BACKEND}/api/update-registeredfunds/${params.id}`
    );
    result = await result.json();
    setSubheading(result.subheading);
    setHeading(result.heading);
    setDesc(result.desc);
    setButtonOne(result.buttonone);
    setSecondHeading(result.secondheading);
    setDescTwo(result.desctwo);
    setThirdHeading(result.thirdheading);
    setThirdDesc(result.thirddesc);
    setThirdButton(result.thirdbutton);
    setCtaTitleHeading(result.ctatitleheading);
    setCtaSubheading(result.ctasubheading);
    setCtaButton(result.ctabutton);
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    submitProduct(); // Call the submitProduct function here
  };

  const submitProduct = async () => {
    const baseUrl = `${process.env.REACT_APP_BACKEND}/api/update-registeredfunds/${params.id}`;
    const data = {
      subheading,
      heading,
      desc,
      buttonone,
      secondheading,
      desctwo,
      thirdheading,
      thirddesc,
      thirdbutton,
      ctatitleheading,
      ctasubheading,
      ctabutton,
    };
    const result = await request({ url: baseUrl, method: "PUT", body: data });
    if (result) {
      console.log(result);
      navigate("/pages-list");
    }

    // replace with actual submission logic
  };

  return (
    <div className="content-wrapper">
      <div className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1 className="m-0">Update</h1>
            </div>
            {/* /.col */}
            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right"></ol>
            </div>
            {/* /.col */}
          </div>
          {/* /.row */}
        </div>
        {/* /.container-fluid */}
      </div>
      <div className="adminform-background px-3 mx-3 py-3">
        <form onSubmit={handleSubmit}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextField
                required
                label="Subheading"
                value={subheading}
                onChange={(event) => setSubheading(event.target.value)}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                label="Heading"
                value={heading}
                onChange={(event) => setHeading(event.target.value)}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                label="Description"
                value={desc}
                onChange={(event) => setDesc(event.target.value)}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                label="Button One"
                value={buttonone}
                onChange={(event) => setButtonOne(event.target.value)}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                label="Second Heading"
                value={secondheading}
                onChange={(event) => setSecondHeading(event.target.value)}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                label="Second Description"
                value={desctwo}
                onChange={(event) => setDescTwo(event.target.value)}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                label="Third Heading"
                value={thirdheading}
                onChange={(event) => setThirdHeading(event.target.value)}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <InputLabel htmlFor="name-input">Description</InputLabel>

              <Editor
                apiKey={process.env.REACT_APP_TINYMCE_KEY}
                textareaName="product Description"
                value={thirddesc}
                //onEditorChange={(e)=>SetDescription(e.target.value)}
                onEditorChange={(newValue, editor) => setThirdDesc(newValue)}
                init={{
                  height: 500,
                  menubar: true,
                  plugins:
                    "autolink lists link image charmap print preview anchor searchreplace visualblocks code fullscreen insertdatetime media table paste code help wordcount",

                  toolbar:
                    "undo redo | formatselect | " +
                    "bold italic backcolor | alignleft aligncenter " +
                    "alignright alignjustify | bullist numlist outdent indent | " +
                    "removeformat | help",
                  content_style:
                    "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                label="Third Button"
                value={thirdbutton}
                onChange={(event) => setThirdButton(event.target.value)}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                label="CTA Title Heading"
                value={ctatitleheading}
                onChange={(event) => setCtaTitleHeading(event.target.value)}
                fullWidth
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                label="CTA Title Heading"
                value={ctasubheading}
                onChange={(e) => setCtaSubheading(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                label="CTA Button"
                value={ctabutton}
                onChange={(e) => setCtaButton(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <Button type="submit" variant="contained" color="primary">
                Submit
              </Button>
            </Grid>
          </Grid>
        </form>
      </div>
    </div>
  );
};

export default UpdateRegisteredFunds;
