import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Editor } from "@tinymce/tinymce-react";
import {
  TextField,
  Button,
  Grid,
  makeStyles,
  InputLabel,
} from "@material-ui/core";
import "../../index.css";
import "react-tagsinput/react-tagsinput.css";
import request from "../../../utils/http-client";
import { useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      width: "100%",
    },
  },
}));

const NewWebinars = () => {
  const classes = useStyles();
  const [eventstitle, setName] = useState("");
  const [description, setDesc] = useState("");
  const [excerpt, setExcerpt] = useState("");
  const [youtubevideolink, setyoutubevideolink] = useState("");
  const [file, setFile] = useState();
  const [fileName, setFileName] = useState("");
  const [error, SetError] = useState("");
  const params = useParams();
  const currentUser = useSelector((state) => state.user.info);

  const navigate = useNavigate();

  const handleNameChange = (event) => {
    setName(event.target.value);
  };

  const handleExcerptChange = (event) => {
    setExcerpt(event.target.value);
  };
  const handleyoutubevideoChange = (event) => {
    setyoutubevideolink(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    submitProduct(); // Call the submitProduct function here
  };

  useEffect(() => {
    getTeamDetails();
  }, []);

  const getTeamDetails = async () => {
    console.warn(params);
    let result = await fetch(
      `${process.env.REACT_APP_BACKEND}/api/update-webinars/${params.id}`
    );
    result = await result.json();
    setName(result.eventstitle);
    setDesc(result.description);
    setExcerpt(result.excerpt);
    setyoutubevideolink(result.youtubevideolink);
  };

  const submitProduct = async () => {
    const userID = currentUser._id;
    const baseUrl = `${process.env.REACT_APP_BACKEND}/api/update-webinars/${params.id}`;
    const data = {
      eventstitle: eventstitle,
      description: description,
      excerpt: excerpt,
      youtubevideolink: youtubevideolink,
      userID: userID,
    };
    const formData = new FormData();
    for (let name in data) {
      formData.append(name, data[name]);
    }
    formData.append("file", file);
    const result = await request({
      method: "put",
      url: baseUrl,
      data: formData,
    });
    if (result) {
      navigate("/webinar-list");
    }
  };

  const saveFile = (e) => {
    setFile(e.target.files[0]);
    setFileName(e.target.files[0].name);
  };

  return (
    <>
      <div className="content-wrapper">
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1 className="m-0">Update Webinars</h1>
              </div>
              {/* /.col */}
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right"></ol>
              </div>
              {/* /.col */}
            </div>
            {/* /.row */}
          </div>
          {/* /.container-fluid */}
        </div>

        <div className="adminform-background px-3 mx-3 py-3">
          <form className={classes.root} onSubmit={handleSubmit}>
            <Grid container spacing={4}>
              <Grid item xs={12}>
                <TextField
                  required
                  label="Webinars Title"
                  value={eventstitle}
                  onChange={handleNameChange}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  label="Webinars Excerpt"
                  value={excerpt}
                  onChange={handleExcerptChange}
                />
              </Grid>
              <Grid item xs={12}>
                <InputLabel htmlFor="name-input">
                  Webinars Description
                </InputLabel>

                <Editor
                  apiKey={process.env.REACT_APP_TINYMCE_KEY}
                  textareaName="product Description"
                  value={description}
                  //onEditorChange={(e)=>SetDescription(e.target.value)}
                  onEditorChange={(newValue, editor) => setDesc(newValue)}
                  init={{
                    height: 500,
                    menubar: true,
                    plugins:
                      "autolink lists link image charmap print preview anchor searchreplace visualblocks code fullscreen insertdatetime media table paste code help wordcount",

                    toolbar:
                      "undo redo | formatselect | " +
                      "bold italic backcolor | alignleft aligncenter " +
                      "alignright alignjustify | bullist numlist outdent indent | " +
                      "removeformat | help",
                    content_style:
                      "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                  }}
                />
              </Grid>

              <Grid item xs={6}>
                <TextField
                  required
                  label="Youtube Playlist Link"
                  value={youtubevideolink}
                  onChange={handleyoutubevideoChange}
                />
              </Grid>
              <Grid item xs={6}>
                <input
                  accept="image/*"
                  id="thumbnail-upload"
                  type="file"
                  onChange={saveFile}
                  style={{ display: "none" }}
                />
                {fileName && (
                  <div className="img-box-dashboard-featured">
                    <img
                      src={URL.createObjectURL(file)}
                      alt="Selected file preview"
                    />
                  </div>
                )}
                <label htmlFor="thumbnail-upload">
                  <Button variant="contained" color="primary" component="span">
                    Upload image
                  </Button>
                </label>
              </Grid>
              <Grid item xs={12}>
                <Button variant="contained" color="primary" type="submit">
                  Save
                </Button>
              </Grid>
            </Grid>
          </form>
        </div>
      </div>
    </>
  );
};

export default NewWebinars;
