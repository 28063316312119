import React,{useState,useEffect} from 'react'
import { Container , Row , Col } from 'react-bootstrap'
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import blog from '../../assets/blog.webp'
import newsletter from '../../assets/newsletters.webp'
import events from '../../assets/Joot_events_web.webp'

import './resources.css'
import { Link } from 'react-router-dom';
const Intro = () => {

    const [partner, setpartner] = useState([]);

    const fetchpartnerpage = async () => {
        const response = await fetch(`${process.env.REACT_APP_BACKEND}/api/get-reourcespage`);
        const result = await response.json();
        setpartner(result);
      };
      useEffect(() => {
          fetchpartnerpage();
      }, []);
  return (

        <section className='resources-sec-1'>
            <Container>
                
                <Row>
                {partner.map((row, index) => (

                        <>
                    <h1>{row.heading}</h1>
                    <p className='py-3'>{row.subheading}</p>
                    </>
                ))}
                    <Col>
                    <h4> Joot Blog </h4>
                    <Card >
                        <Card.Img variant="top" src={blog} />
                        <Card.Body className='res-card'>
                            {/* <Card.Title>Card Title</Card.Title> */}
                            <Card.Text>
                            Current perspectives, news, <br/>and updates from Joot
                            </Card.Text>
                            <Link to={'/blog'}>
                            <Button variant="primary">VISIT BLOG</Button>
                            </Link>
                        </Card.Body>
                    </Card>
                    </Col>
                    <Col>
                    <h4> Newsletters</h4>
                    <Card >
                        <Card.Img variant="top" src={newsletter} />
                        <Card.Body className='res-card text-center'>
                            {/* <Card.Title className='text-center'>Compliance Newsletter</Card.Title> */}
                            <Card.Text>
                            A quarterly roundup of what you need to know

</Card.Text>
                            <Link to={'/resources/compliance-newsletter'}>
                            <Button variant="primary">BROWSE ISSUES</Button>
                            </Link>
                        </Card.Body>
                    </Card>
                    </Col>
                    <Col>
                    <h4> Events</h4>
                    <Card >
                        <Card.Img variant="top" src={events} />
                        <Card.Body className='res-card'>
                            {/* <Card.Title>Card Title</Card.Title> */}
                            <Card.Text>
                            Events designed to involve you in the future of compliance

</Card.Text>
                            <Link to={'/events'}>
                            <Button variant="primary">VISIT EVENTS</Button>
                            </Link>
                        </Card.Body>
                    </Card>
                    </Col>
                    
                </Row>
            </Container>
        </section>
    )
}

export default Intro