import React from 'react'
import Footer from '../../Components/Footer/Footer'
import Header from '../../Components/Header/Header'
import Intro from './Intro'
import Secondsec from './Secondsec'
import { Helmet } from 'react-helmet'

const index = () => {
  return (
    <>
    <Helmet>
      <title>
      Contact Joot | SEC Compliance Services

      </title>

      <meta name="description" content="Contact Joot's experienced compliance team to assist with a variety of financial compliance needs. Joot makes compliance easy.
"/>
    </Helmet>
    <Header/>
    <React.Fragment>
        <Intro/>
        <Secondsec/>

    </React.Fragment>
    <Footer/>
    </>
    )
}

export default index