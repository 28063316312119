import React ,{useState,useEffect}from 'react'
import { Container, Row , Col } from 'react-bootstrap'
import Button from 'react-bootstrap/Button';
import img from '../../assets/2.PNG';
import '../Services/services.css'
import './About.css'
import ReactHtmlParser from 'react-html-parser';
const Intro = () => {
    const [partner, setpartner] = useState([]);

    const fetchpartnerpage = async () => {
        const response = await fetch(`${process.env.REACT_APP_BACKEND}/api/get-aboutpage`);
        const result = await response.json();
        setpartner(result);
      };
      useEffect(() => {
          fetchpartnerpage();
      }, []);


      return (
            
        <section className='services-first-sec'>
            <Container>
                <Row>
                    <Col md={7} className="py-5">
                    {partner.map((row, index) => (

                        <>
                        <h1>{row.heading}</h1>
                        <h2 className='py-3'>{row.subheading}</h2>
                        <p>
                        {ReactHtmlParser(row.desc)}
                                                </p>
                        </>
                    ))}
                        {/* <Button variant="outline-light my-3" className="blue-btn">GET IN TOUCH</Button> */}

                    </Col>
                    <Col md={5}>
                        <img className='about-intro-img' src={img}/>
                    </Col>
                </Row>
            </Container>
        </section>

    )
}

export default Intro