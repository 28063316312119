import React from 'react'
import Footer from '../../Components/Footer/Footer'
import Header from '../../Components/Header/Header'
import Intro from './Intro'
import Secondsec from './Secondsec'
import { Helmet } from 'react-helmet'

const index = () => {
  return (
    <>
    <Helmet>
        <title>
        Compliance Industry Partnerships | Joot
        </title>
        <meta name="description" content="Joot partners with some of best firms and groups within the financial compliance industry including the NSCP and IAA.
"/>
    </Helmet>
        <Header/>
        <React.Fragment>
            <Intro/>
            <Secondsec/>
        </React.Fragment>
        <Footer/>
        </>
    )
}

export default index