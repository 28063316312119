import React from 'react'
import Footer from '../../Components/Footer/Footer'
import Header from '../../Components/Header/Header'
import Intro from './Intro'
import Secondsec from './Secondsec'
import { Helmet } from 'react-helmet'

const index = () => {
  return (
        <>
        <Helmet>
            <title>
            Joot Compliance Blog | SEC Compliance News
            </title>
            <meta name="description" content="Learn about the latest in financial compliance news and updates from the SEC from Joot's compliance blog.
"/>
        </Helmet>
            <Header/>
            <React.Fragment>
            <Intro/>
            <Secondsec/>
            </React.Fragment>
            <Footer/>
        </>

    )
}

export default index