import React ,{useEffect,useState}from 'react'
import { Container, Row , Col } from 'react-bootstrap'
import Button from 'react-bootstrap/Button';
import { Link } from 'react-router-dom';
import img from '../../assets/1.PNG';
import './services.css'

const Intro = () => {
    const [partner, setpartner] = useState([]);

    const fetchpartnerpage = async () => {
        const response = await fetch(`${process.env.REACT_APP_BACKEND}/api/get-servicespage`);
        const result = await response.json();
        setpartner(result);
      };
      useEffect(() => {
          fetchpartnerpage();
      }, []);
      return (
        <section className="services-first-sec">
          <Container>
            <Row>
              <Col md={7} className="py-5">
                {partner.map((row, index) => (
                  <React.Fragment key={index}>
                    <h1>{row.heading}</h1>
                    <h2 className="py-3">{row.subheading}</h2>
                    <p>{row.desc}</p>
                    <Link to={row.buttonone}>
                      <Button variant="outline-light my-3" className="blue-btn">
                        GET IN TOUCH
                      </Button>
                    </Link>
                  </React.Fragment>
                ))}
              </Col>
              <Col md={5}>
                <img src={img} />
              </Col>
            </Row>
          </Container>
        </section>
      );
}

export default Intro