import React, { useState ,  useEffect } from "react";
import { Link , useNavigate, useParams } from "react-router-dom";

import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import {
  TextField,
  Button,
  Grid,
  makeStyles,
  InputLabel,
} from "@material-ui/core";
import request from "../../../utils/http-client";

const UpdateAccordian = () => {
  const [packageName, setPackageName] = useState("");
  const [items, setItems] = useState([{ name: "", variationType: "standard" }]);
  const navigate = useNavigate();
  const params = useParams();

  const handleItemChange = (index, e) => {
    const newItem = { ...items[index], [e.target.name]: e.target.value };
    const newItems = [...items];
    newItems[index] = newItem;
    setItems(newItems);
  };

  const handleAddItem = () => {
    setItems([...items, { name: "", variationType: "standard" }]);
  };

  const handleRemoveItem = (index) => {
    const newItems = [...items];
    newItems.splice(index, 1);
    setItems(newItems);
  };

  const handleSubmit = (e) => {
    const baseUrl = `${process.env.REACT_APP_BACKEND}/api/update-accordian/${params.id}`;

    e.preventDefault();
    const newPackage = { packageName, items };
    request({ url: baseUrl, method: "PUT", body: newPackage })
      .then((response) => {
        setPackageName("");
        setItems([{ name: "", variationType: "standard" }]);
        navigate("/accordians-list");
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getTeamDetails();
  }, []);
  const getTeamDetails = async () => {
    let result = await fetch(
      `${process.env.REACT_APP_BACKEND}/api/update-accordian/${params.id}`
    );
    result = await result.json();
    setPackageName(result.packageName);
    setItems(result.items);
  };
  return (
    <div className="content-wrapper">
      <div className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1 className="m-0">Add New Accordions</h1>
            </div>
            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right"></ol>
            </div>
          </div>
        </div>
      </div>
      <div className="adminform-background px-3 mx-3 py-3">
        <form onSubmit={handleSubmit}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextField
                label="Package Name"
                value={packageName}
                onChange={(e) => setPackageName(e.target.value)}
                fullWidth
              />
            </Grid>

            {items.map((item, index) => (
              <Grid key={index} item xs={12}>
                <TextField
                  label="Item Name"
                  name="name"
                  value={item.name}
                  onChange={(e) => handleItemChange(index, e)}
                  fullWidth
                />
                <FormControl component="fieldset" fullWidth>
                  <RadioGroup
                    aria-label="variationType"
                    name="variationType"
                    value={item.variationType}
                    onChange={(e) => handleItemChange(index, e)}
                    fullWidth
                  >
                    <FormControlLabel
                      value="standard"
                      control={<Radio />}
                      label="Standard"
                    />
                    <FormControlLabel
                      value="full"
                      control={<Radio />}
                      label="Full"
                    />
                  </RadioGroup>
                </FormControl>
                <Button
                  onClick={() => handleRemoveItem(index)}
                  variant="contained"
                  color="primary"
                  style={{ backgroundColor: "red" }}
                >
                  Remove
                </Button>
              </Grid>
            ))}
            <Grid item xs={12}>
              <Button
                onClick={handleAddItem}
                variant="contained"
                color="primary"
                style={{ backgroundColor: "green" }}
              >
                Add Item
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Button type="submit" variant="contained" color="primary">
                Submit
              </Button>
            </Grid>
          </Grid>
        </form>
      </div>
    </div>
  );
};

export default UpdateAccordian;
