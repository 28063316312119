import React, { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
  Box,
  CircularProgress,
} from "@material-ui/core";
import DeleteTwoToneIcon from "@mui/icons-material/DeleteTwoTone";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";

import ModeEditOutlineTwoToneIcon from "@mui/icons-material/ModeEditOutlineTwoTone";

import Button from "react-bootstrap/Button";
import { Link } from "react-router-dom";
import "../../index.css";

import { useQuery } from "react-query";
import { getPosts } from "../../../utils/api";
import request from "../../../utils/http-client";

const CircularIndeterminate = () => (
  <Box
    style={{
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "100vh",
    }}
  >
    <CircularProgress />
  </Box>
);

const Data = () => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const { data, refetch: fetchData, isFetching } = useQuery("posts", getPosts);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const DeletePartnership = async (id) => {
    await request({
      url: `${process.env.REACT_APP_BACKEND}/api/delete-posts/${id}`,
      method: "DELETE",
    });
    fetchData();
  };

  return isFetching ? (
    <CircularIndeterminate />
  ) : (
    <div className="content-wrapper">
      <div className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1 className="m-0"> Posts</h1>
            </div>
            {/* /.col */}
            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                <Link to="/post-new">
                  <Button variant="primary" size="lg">
                    Add New
                  </Button>
                </Link>
              </ol>
            </div>
            {/* /.col */}
          </div>
          {/* /.row */}
        </div>
        {/* /.container-fluid */}
      </div>
      <div className="mx-3">
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>S.no #</TableCell>
                <TableCell>Featured Image</TableCell>
                <TableCell>Post Title</TableCell>
                <TableCell>Post Excerpt</TableCell>
                <TableCell>SEO TAGS</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Array.isArray(data) && data.length > 0 ? (
                data
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => (
                    <TableRow key={row._id}>
                      <TableCell>{index + 1 + page * rowsPerPage}</TableCell>
                      <TableCell>
                        <img
                          className="table-backend-img"
                          src={
                            "https://storage.googleapis.com/fintechlegal/joot_posts/" +
                            row.document
                          }
                          alt={row.document} // Provide alt text for accessibility
                        />
                      </TableCell>
                      <TableCell>{row.posttitle}</TableCell>
                      <TableCell>{row.excerpt}</TableCell>
                      <TableCell>
                        <b>{row.tags}</b>
                      </TableCell>
                      <TableCell>
                        <Link to={"/update-blogpost/" + row.slug}>
                          <ModeEditOutlineTwoToneIcon></ModeEditOutlineTwoToneIcon>
                        </Link>
                        <DeleteTwoToneIcon
                          onClick={() => DeletePartnership(row._id)}
                        ></DeleteTwoToneIcon>
                      </TableCell>
                    </TableRow>
                  ))
              ) : (
                <TableRow>
                  <TableCell colSpan={6}>No records found.</TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={data ? data.length : 0}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </TableContainer>
      </div>
    </div>
  );
};

export default Data;
