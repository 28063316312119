import React from 'react'
import Footer from '../../Components/Footer/Footer'
import Header from '../../Components/Header/Header'
import Intro from './Intro'
import SecondrySec from './SecondrySec'
import Thirdsec from './Thirdsec'
import { Helmet } from 'react-helmet'


const index = () => {
  return (
    <>

    <Helmet>
        <title>
        Joot Compliance Newsletter | Compliance Update
        </title>
        <meta name="description" content="Download Joot's quarterly newsletter and stay up to date with the latest in SEC financial compliance news.
"/>
    </Helmet>
        <Header/>
        <React.Fragment>
            <Intro/>
            <SecondrySec/>
            <Thirdsec/>          
        </React.Fragment>
        <Footer/>
        </>
    )
}

export default index