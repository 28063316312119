import React, { useState,useEffect } from "react";
import { Link , useNavigate, useParams} from "react-router-dom";
import Grid from "@material-ui/core/Grid";

import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";

import request from "../../../utils/http-client";

const UpdateServices = () => {
  const [heading, setHeading] = useState("");
  const [subheading, setSubheading] = useState("");
  const [desc, setDesc] = useState("");
  const [buttonone, setButtonOne] = useState("");
  const [secondheading, setSecondHeading] = useState("");
  const [secondsubheading, setSecondSubheading] = useState("");
  const [ctatitleheading, setCtaTitleHeading] = useState("");
  const [ctabutton, setCtaButton] = useState("");
  const navigate = useNavigate();
  const params = useParams();

  useEffect(() => {
    getTeamDetails();
  }, []);

  const getTeamDetails = async () => {
    console.warn(params);
    let result = await fetch(
      `${process.env.REACT_APP_BACKEND}/api/update-servicespage/${params.id}`
    );
    result = await result.json();
    setHeading(result.heading);
    setSubheading(result.subheading);
    setDesc(result.desc);
    setButtonOne(result.buttonone);
    setSecondHeading(result.secondheading);
    setSecondSubheading(result.secondsubheading);
    setCtaTitleHeading(result.ctatitleheading);
    setCtaButton(result.ctabutton);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    submitProduct(); // Call the submitProduct function here
  };

  const submitProduct = async () => {
    const baseUrl = `${process.env.REACT_APP_BACKEND}/api/update-servicespage/${params.id}`;
    const data = {
      heading,
      subheading,
      desc,
      buttonone,
      secondheading,
      secondsubheading,
      ctatitleheading,
      ctabutton,
    };
    const result = await request({ url: baseUrl, method: "PUT", body: data });
    if (result) {
      navigate("/pages-list");
    }

    // replace with actual submission logic
  };

  return (
    <div className="content-wrapper">
      <div className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1 className="m-0">Update</h1>
            </div>
            {/* /.col */}
            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right"></ol>
            </div>
            {/* /.col */}
          </div>
          {/* /.row */}
        </div>
        {/* /.container-fluid */}
      </div>
      <div className="adminform-background px-3 mx-3 py-3">
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                label="Heading"
                value={heading}
                onChange={(e) => setHeading(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                label="Subheading"
                value={subheading}
                onChange={(e) => setSubheading(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                label="Description"
                value={desc}
                onChange={(e) => setDesc(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                label="Button One"
                value={buttonone}
                onChange={(e) => setButtonOne(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                label="Second Heading"
                value={secondheading}
                onChange={(e) => setSecondHeading(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                label="Second Subheading"
                value={secondsubheading}
                onChange={(e) => setSecondSubheading(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                label="CTA Title Heading"
                value={ctatitleheading}
                onChange={(e) => setCtaTitleHeading(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                label="CTA Button"
                value={ctabutton}
                onChange={(e) => setCtaButton(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <Button type="submit" variant="contained" color="primary">
                Update
              </Button>
            </Grid>
          </Grid>
        </form>
      </div>
    </div>
  );
};

export default UpdateServices;
