import React, { useState, useEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap'
import Card from 'react-bootstrap/Card';
import ReactHtmlParser from 'react-html-parser';

const Thirdsec = () => {
    const [data, setData] = useState([]);

  const fetchData = async () => {
      const response = await fetch(`${process.env.REACT_APP_BACKEND}/api/get-homepage`);
      const result = await response.json();
      setData(result);
    };
    useEffect(() => {
      fetchData();
    }, []);


    const [outsource, setOutsource] = useState([]);

    const fetchOutsource = async () => {
        const response = await fetch(`${process.env.REACT_APP_BACKEND}/api/get-outsources`);
        const result = await response.json();
        setOutsource(result);
      };
      useEffect(() => {
        fetchOutsource();
      }, []);

  return (
        <section className='third-sec-parent'>
            <Container>
            {data.map((row, index) => (

                <Row className='third-sec'>
                    <h1 className='text-center'>{row.headingthree}</h1>
                </Row>
            ))}
                <Row className='third-sec py-5'>
                {outsource.map((row, index) => (

                    <Col md={4}>
                    <Card >
                    <Card.Header className={`card-header ${index === 0 ? 'first-card' : index === 1 ? 'secondd-card' : 'thirdd-card'}`}>
                            <span>{row.outsourcenumber}</span><br/>{row.outsourcetitle}</Card.Header>
                        <Card.Body>
                        <Card.Title className='c-t'></Card.Title>
                        <Card.Text className='c-p'>
                        {ReactHtmlParser(row.outsourcedesc)}
                        </Card.Text>
                        </Card.Body>
                    </Card>
                    </Col>
                                ))}

                    
                </Row>
            </Container>
        </section>

    )
}

export default Thirdsec