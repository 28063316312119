import React,{useEffect,useState} from 'react'
import { Container, Row , Col } from 'react-bootstrap'
import Button from 'react-bootstrap/Button';


const Secondsec = () => {
    
    const [partner, setpartner] = useState([]);

    const fetchpartnerpage = async () => {
        const response = await fetch(`${process.env.REACT_APP_BACKEND}/api/get-partnerpage`);
        const result = await response.json();
        setpartner(result);
      };
      useEffect(() => {
          fetchpartnerpage();
      }, []);

  return (
    <section className='fourth-sec'>
        <Container>
            <Row>
                <Col>
                {partner.map((row, index) => (
<>
                    <h1 className='refunds-h1'>{row.ctatitleheading}</h1>
                    <p className='refunds-p'>{row.ctasubheading}</p>
                    </>
                ))}
                </Col>
            </Row>
        </Container>
    </section>

    )
}

export default Secondsec