import React, { useState, useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import { Link } from "react-router-dom";
import partner from "../../assets/partners.jpeg";
import "../ReFunds/ReFunds.css";

const Intro = () => {
  const styles = {
    width: "300px",
    margin: "0 auto",
    textAlign: "center",
    display: "table",
  };

  const [data, setData] = useState([]);
  const [membership, setMembership] = useState([]);

  const fetchData = async () => {
    const response = await fetch(
      `${process.env.REACT_APP_BACKEND}/api/get-partnership`
    );
    const result = await response.json();
    setData(result);
  };
  useEffect(() => {
    fetchData();
  }, []);

  const fetchmembership = async () => {
    const response = await fetch(
      `${process.env.REACT_APP_BACKEND}/api/get-membership`
    );
    const result = await response.json();
    setMembership(result);
  };
  useEffect(() => {
    fetchmembership();
  }, []);

  const [partner, setpartner] = useState([]);

  const fetchpartnerpage = async () => {
    const response = await fetch(
      `${process.env.REACT_APP_BACKEND}/api/get-partnerpage`
    );
    const result = await response.json();
    setpartner(result);
  };
  useEffect(() => {
    fetchpartnerpage();
  }, []);

  return (
    <section className="refunds-service-sec-1">
      <Container>
        <Row>
          <Col>
            {partner.map((row, index) => (
              <>
                <h1>{row.heading}</h1>
                <p>{row.subheading}</p>
                <Link to={row.buttonone}>
                  <Button
                    variant="outline-light my-5"
                    className="blue-btn refundbtn"
                  >
                    JOIN US
                  </Button>
                </Link>
              </>
            ))}
          </Col>
        </Row>
        <Row className="py-5">
          {data.map((row, index) => (
            <Col md={3}>
              <Link to={row.name}>
                <img
                  className="py-5"
                  src={
                    "https://storage.googleapis.com/fintechlegal/joot_posts/" +
                    row.document
                  }
                  style={styles}
                />
              </Link>
            </Col>
          ))}
        </Row>

        <Row className="py-5">
          <Col className="py-5">
            {partner.map((row, index) => (
              <>
                <h1>{row.secondsecheading}</h1>
                <p>{row.secondsecsubheading}</p>
              </>
            ))}
          </Col>
        </Row>
        <Row className="py-5">
          {membership.map((row, index) => (
            <Col md={6}>
              <Link to={row.name}>
                <img
                  className={`py-${index === 1 ? 1 : 5}`}
                  src={
                    "https://storage.googleapis.com/fintechlegal/joot_posts/" +
                    row.document
                  }
                  style={styles}
                />
              </Link>
            </Col>
          ))}
        </Row>
      </Container>
    </section>
  );
};

export default Intro;
