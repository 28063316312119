import React from 'react'
import Footer from '../../Components/Footer/Footer'
import Header from '../../Components/Header/Header'
import Intro from './Intro'
import Secondsec from './Secondsec'
import Thirdsec from './Thirdsec'
import { Helmet } from 'react-helmet'

const index = () => {
  return (
        <>
        <Helmet>
            <title>
            Joot Compliance Resources | Compliance Blog
            </title>
            <meta name="description" content="Read and downloaded compliance resources including Joot's compliance newsletter, compliance blog, and other SEC compliance items.
"/>
        </Helmet>
    <Header/>
    <React.Fragment>
        <Intro/>
        <Secondsec/>
        <Thirdsec/>
    </React.Fragment>
        <Footer/>
    </>
    )
}

export default index