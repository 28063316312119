import React from 'react'
import Footer from '../../Components/Footer/Footer'
import Header from '../../Components/Header/Header'
import Intro from './Intro'
import Secondsec from './Secondsec'
import Thirdsec from './Thirdsec'
import Fourthsec from './Fourthsec'
import { Helmet } from 'react-helmet'
const index = () => {
  return (
    <>
    <Helmet>
      <title>
      Mock SEC Exam | Joot SEC Exam Compliance
      </title>
      <meta name="description" content="Joot's SEC compliance team provides comprehensive mock SEC exam services to prepare registered investment advisers for a full SEC examination.
"/>
    </Helmet>
        <Header/>
        <React.Fragment>
        <Intro/>
        <Secondsec/>
        <Thirdsec/>
        <Fourthsec/>
        </React.Fragment>
        <Footer/>
        </>
    )
}

export default index