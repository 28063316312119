import React from 'react'
import Footer from '../../Components/Footer/Footer'
import Header from '../../Components/Header/Header'
import Blogsec from './Blogsec'
import Fourthsec from './Fourthsec'
import Intro from './Intro'
import Secondsec from './Secondsec'
import Thirdsec from './Thirdsec'

const index = () => {
  return (
    <>
    <Header/>
    <React.Fragment>
    <Intro/>
    <Secondsec/>
    <Thirdsec/>
    <Fourthsec/>
    <Blogsec/>    
    </React.Fragment>
    <Footer/>
</>
    )
}

export default index