
import React, { useState, useEffect } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
} from '@material-ui/core';
import DeleteTwoToneIcon from "@mui/icons-material/DeleteTwoTone";
import ModeEditOutlineTwoToneIcon from "@mui/icons-material/ModeEditOutlineTwoTone";
import Button from "react-bootstrap/Button";
import { Link } from "react-router-dom";

import "../../index.css";
import request from "../../../utils/http-client";

const Data = () => {
  const [data, setData] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const fetchData = async () => {
    try {
      const response = await request({
        url: `${process.env.REACT_APP_BACKEND}/api/get-accordian`,
        method: "GET",
      });
      setData(response);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleDelete = async (id) => {
    try {
      await request({
        url: `${process.env.REACT_APP_BACKEND}/api/delete-accordian/${id}`,
        method: "DELETE",
      });
      fetchData();
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className="content-wrapper">
      <div className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1 className="m-0"> Accordions List</h1>
            </div>
            {/* /.col */}
            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                <Link to="/accordians-new">
                  <Button variant="primary" size="lg">
                    Add New
                  </Button>
                </Link>
              </ol>
            </div>
            {/* /.col */}
          </div>
          {/* /.row */}
        </div>
        {/* /.container-fluid */}
      </div>
      <div className="mx-3">
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>S.no #</TableCell>
                <TableCell>Title</TableCell>
                <TableCell>Items</TableCell>

                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Array.isArray(data) && data.length > 0 ? (
                data
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((pkg, index) => (
                    <TableRow key={pkg._id}>
                      <TableCell>{index + 1}</TableCell>
                      <TableCell>{pkg.packageName}</TableCell>
                      <TableCell>
                        <ul>
                          {pkg.items.map((item, index) => (
                            <li key={index}>
                              {item.name} ({item.variationType})
                            </li>
                          ))}
                        </ul>
                      </TableCell>

                      <TableCell>
                        <Link to={"/update-updateaccordian/" + pkg._id}>
                          <ModeEditOutlineTwoToneIcon></ModeEditOutlineTwoToneIcon>
                        </Link>

                        <DeleteTwoToneIcon
                          onClick={() => handleDelete(pkg._id)}
                        ></DeleteTwoToneIcon>
                      </TableCell>
                    </TableRow>
                  ))
              ) : (
                <TableRow>
                  <TableCell colSpan={6}>No records found.</TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={data.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </TableContainer>
      </div>
    </div>
  );
};

export default Data;


