import React, { useState,useEffect,useRef } from 'react';
import { Link , useNavigate} from "react-router-dom";

import { Col, Container, Row } from "react-bootstrap";
import { Form, Button } from "react-bootstrap";
import ReactHtmlParser from "react-html-parser";
import request from "../../utils/http-client";

const Secondsec = () => {
  const [fname, setFirstName] = useState("");
  const [lname, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [jobTitle, setJobTitle] = useState("");
  const [company, setCompany] = useState("");
  const [message, setMessage] = useState("");
  const navigate = useNavigate();

  const handleSubmit = async () => {
    const baseUrl = `${process.env.REACT_APP_BACKEND}/api/contact`;
    const data = {
      fname: fname,
      lname: lname,
      email: email,
      jobTitle: jobTitle,
      company: company,
      message: message,
    };

    const result = await request({ url: baseUrl, method: "POST", body: data });
    if (result) {
      console.log(result);
      navigate("/contact");
    }
  };
  const styles = {
    width: "700px",
    height: "800px",
  };
  const [data, setData] = useState([]);

  const fetchData = async () => {
    const response = await fetch(
      `${process.env.REACT_APP_BACKEND}/api/get-contactpage`
    );
    const result = await response.json();
    setData(result);
  };
  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <section className="second-sec-contact">
        <Container>
          <Row>
            <Col>
              {data.map((row, index) => (
                <>
                  <p>{ReactHtmlParser(row.desc)}</p>
                </>
              ))}
            </Col>
          </Row>
          <Row className="text-center mx-auto d-table">
            <Col md={6}>
              <iframe
                frameborder="0"
                style={styles}
                src="https://forms.zohopublic.com/codyfoster/form/ContactForm/formperma/4wj-0KxWbTdm2-VCxmxpC40CVPsBVjHJMgLlcz6GQug"
              ></iframe>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default Secondsec