import React, { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Button from "react-bootstrap/Button";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ReactHtmlParser from "react-html-parser";
import { Col, Container, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCoffee, faFaceMehBlank } from "@fortawesome/free-solid-svg-icons";
import {
  faFacebook,
  faLinkedin,
  faTwitter,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";
import Card from "react-bootstrap/Card";
import "./blogdetail.css";
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
} from "react-share";

const Secondsec = () => {
  const [posttitle, setName] = useState("");
  const [description, setDesc] = useState("");
  const [excerpt, setExcerpt] = useState("");
  const [file, setFile] = useState();
  const [fileName, setFileName] = useState("");
  const [error, SetError] = useState("");
  const [metatitle, setMetatitle] = useState("");
  const [metadescription, setMetadescription] = useState("");
  const [tags, setTags] = useState([]);
  const [date, setDate] = useState("");
  const [posts, setPosts] = useState([]);
  const params = useParams();
  const shareUrl = window.location.href;

  const styles = {
    width: "100%",
  };

  useEffect(() => {
    const getBlogDetails = async () => {
      let result = await fetch(
        `${process.env.REACT_APP_BACKEND}/api/update-blog/${params.slug}`
      );

      result = await result.json();

      setName(result.posttitle);
      setDesc(result.description);
      setExcerpt(result.excerpt);
      setMetatitle(result.metatitle);
      setMetadescription(result.metadescription);
      setFileName(result.document);

      setTags(result.tags);
      setDate(result.date); // Retrieve date from _id field
      setData([{ ...result, selectedTeam: result.selectedTeam }]); // set the data for the current post with the selectedTeam details
    };

    const getLatestPosts = async (pageNum) => {
      try {
        const response = await fetch(
          `${
            process.env.REACT_APP_BACKEND
          }/api/get-posts?page=${pageNum}&pageSize=${2}`
        );
        const result = await response.json();
        let recentPost = result[1] || {};
        if (result[1].slug === params.slug) {
          recentPost = result[0] || {};
        }
        setPosts([recentPost]);
      } catch (err) {
        setPosts([]);
      }
    };

    getLatestPosts(1);
    getBlogDetails();
  }, []);

  const [data, setData] = useState([]);

  return (
    <>
      <head>
        <title>{metatitle}</title>
        <meta name="description" content={metadescription} />
        <meta property="og:title" content={metatitle} />
        <meta property="og:description" content={metadescription} />
        <meta
          property="og:image"
          content={`https://storage.googleapis.com/fintechlegal/joot_posts/${fileName}`}
        />
      </head>

      <section className="blog-inner-detail-sec">
        <Container fluid>
          <Row>
            <Link to="/blog" className="blog-detail-return">
              <ArrowBackIcon fontSize="large" color="white" />
              Return To Joot Blog
            </Link>
          </Row>
          <Row>
            <Col className="blog-inner-detail-left-col" md={8}>
              <h1>{posttitle}</h1>
              <div
                style={{
                  position: "relative",
                  width: "100%",
                  marginBottom: "24px",
                }}
              >
                <div
                  style={{
                    width: "100%",
                    height: "0",
                    paddingTop: "56%",
                    background: `url('https://storage.googleapis.com/fintechlegal/joot_posts/${fileName}') center/cover no-repeat`,
                  }}
                  src={`https://storage.googleapis.com/fintechlegal/joot_posts/${fileName}`}
                />
              </div>
              {/* <p className="blog-date">{new Date(date).toLocaleDateString()}</p> */}

              <p className="blog-inner-detail-desc">
                {ReactHtmlParser(description)}
              </p>
              <Row className="py-5">
                <h2 className="py-3">Author</h2>
                {data.slice(0, 2).map((row, index) => (
                  <>
                    <Col md={12} className="d-flex">
                      <img
                        className="table-backend-img-author"
                        src={
                          "https://storage.googleapis.com/fintechlegal/joot_posts/" +
                          row.selectedTeam.document
                        }
                      />

                      <div className="w-100">
                        {row.selectedTeam ? (
                          <>
                            <h6 className="blogpage-author">
                              {row.selectedTeam.membername}
                            </h6>
                            <p className="blogpage-author-position">
                              {row.selectedTeam.memberposition}
                            </p>
                          </>
                        ) : (
                          <>
                            <h6 className="blogpage-author">Author Name</h6>
                            <p className="blogpage-author-position">
                              Author Position
                            </p>
                          </>
                        )}
                      </div>
                    </Col>
                  </>
                ))}
              </Row>
              <h2>Topics</h2>
              <Button variant="outline-light" className="blogtag-btn">
                {tags}
              </Button>

              <Row className="py-5">
                <h2 className="py-3">Share</h2>
                <Col>
                  <FacebookShareButton url={shareUrl}>
                    <FontAwesomeIcon
                      icon={faFacebook}
                      size="3x"
                      className="pr-4"
                    />
                  </FacebookShareButton>
                  <LinkedinShareButton url={shareUrl}>
                    <FontAwesomeIcon
                      icon={faLinkedin}
                      size="3x"
                      className="px-4"
                    />
                  </LinkedinShareButton>
                  <TwitterShareButton url={shareUrl}>
                    <FontAwesomeIcon icon={faTwitter} size="3x" />
                  </TwitterShareButton>
                </Col>
              </Row>
            </Col>
            <Col className="" md={4}>
              <div className="blog-inner-detail-right-col">
                <h2>Recent Posts</h2>

                {posts.map((row, index) => (
                  <Link
                    to={"/blog/" + row.slug}
                    className="blog-link"
                    target="_blank"
                  >
                    <Row className="blog1stcol">
                      <Card className="blog-box">
                        <Card.Img
                          variant="top"
                          src={
                            "https://storage.googleapis.com/fintechlegal/joot_posts/" +
                            row.document
                          }
                        />
                        <Card.Body className="blog-body">
                          <p className="blog-date">
                            {new Date(
                              parseInt(row._id.substring(0, 8), 16) * 1000
                            ).toLocaleDateString()}
                          </p>
                          <Card.Title className="blog-title">
                            {row.posttitle}
                          </Card.Title>
                          <Card.Text className="blog-exerpt">
                            {row.excerpt}
                          </Card.Text>
                        </Card.Body>
                      </Card>
                    </Row>
                  </Link>
                ))}
              </div>
              <div className="blog-inner-detail-right-col">
                <h2>Popular Topics</h2>
                {Array.from(
                  new Set(data.flatMap((row) => row.tags.split(",")))
                ).map((tag) => (
                  <div key={tag}>
                    <Button variant="outline-light" className="blogtag-btn">
                      {tag.trim()}
                    </Button>
                  </div>
                ))}
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default Secondsec;
