import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import LazyLoad from "react-lazyload";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import "./blog.css";
import "../Home/Home.css";

const ITEMS_PER_PAGE = 6; // Number of blog items per page

const Secondsec = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [totalCount, setTotalCount] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [posts, setPosts] = useState([]);

  useEffect(() => {
    const getPostsCount = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_BACKEND}/api/get-posts-count`
        );
        const result = await response.json();
        setTotalCount(result.totalCount);
      } catch (err) {
        setTotalCount(0);
      }
    };

    getPostsCount();
    getPostsPage(1);
  }, []);

  const getPostsPage = async (pageNum) => {
    setIsLoading(true);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND}/api/get-posts?page=${pageNum}&pageSize=${ITEMS_PER_PAGE}`
      );
      const result = await response.json();
      setPosts(result);
    } catch (err) {
      setPosts([]);
    }
    setIsLoading(false);
  };

  const renderLoader = () => (
    <div
      style={{
        position: posts.length ? "absolute" : "relative",
        top: posts.length ? "20%" : "0",
        zIndex: 100,
        marginBottom: posts.length ? "0px" : "34px",
      }}
    >
      <div
        class="spinner-border"
        role="status"
        style={{
          color: "#D43167",
          display: "block",
          margin: "auto",
          width: "50px",
          height: "50px",
        }}
      >
        <span class="sr-only">Loading...</span>
      </div>
    </div>
  );

  return (
    <section className="blog-page-second-sec">
      <Container>
        <Row>
          <h1>Get the Compliance News You Need</h1>
          <p>
            Stay up to date with perspectives, news, and updates pertaining to
            investment management <br /> technology, legal, compliance, and
            more.
          </p>

          <Row style={{ position: "relative" }}>
            {isLoading && renderLoader()}
            {posts.map((row, index) => (
              <Col
                md={4}
                className="blog1stcol"
                key={index}
                style={{ opacity: isLoading ? "50%" : "100%" }}
              >
                {row.selectedTeam && (
                  <Link to={"/blog/" + row.slug} className="blog-link">
                    <Card className="blog-box">
                      <LazyLoad height={200}>
                        <Card.Img
                          variant="top"
                          src={
                            "https://storage.googleapis.com/fintechlegal/joot_posts/" +
                            row.document
                          }
                        />
                      </LazyLoad>
                      <Card.Body className="blog-body">
                        <p className="blogpage-date">
                          {new Date(row.date).toLocaleDateString()}
                        </p>
                        <Card.Title className="blogpage-title">
                          {row.posttitle}
                        </Card.Title>
                        <Card.Text className="blogpage-exerpt">
                          {row.excerpt}
                        </Card.Text>
                        <Row>
                          <Col md={1}>
                            <LazyLoad height={50}>
                              {row.selectedTeam && row.selectedTeam.document ? (
                                <img
                                  className="table-backend-img-author"
                                  src={
                                    "https://storage.googleapis.com/fintechlegal/joot_posts/" +
                                    row.selectedTeam.document
                                  }
                                  alt="Author"
                                />
                              ) : null}
                            </LazyLoad>
                          </Col>
                          <Col md={11}>
                            {row.selectedTeam ? (
                              <>
                                <h6 className="blogpage-author">
                                  {row.selectedTeam.membername}
                                </h6>
                                <p className="blogpage-author-position">
                                  {row.selectedTeam.memberposition}
                                </p>
                              </>
                            ) : (
                              <>
                                <h6 className="blogpage-author">Author Name</h6>
                                <p className="blogpage-author-position">
                                  Author Position
                                </p>
                              </>
                            )}
                          </Col>
                        </Row>
                        <Row>
                          <Col className="blog-tag-col">
                            {row.tags.split(",").map((tag) => (
                              <div key={tag}>
                                <Button
                                  variant="outline-light"
                                  className="blogtag-btn"
                                >
                                  {tag.trim()}
                                </Button>
                              </div>
                            ))}
                          </Col>
                        </Row>
                      </Card.Body>
                    </Card>
                  </Link>
                )}
              </Col>
            ))}
          </Row>
        </Row>

        {/* Pagination */}
        <Row>
          <Col>
            <Pagination
              currentPage={currentPage}
              itemsPerPage={ITEMS_PER_PAGE}
              totalItems={totalCount}
              onPageChange={(page) => {
                setCurrentPage(page);
                getPostsPage(page);
              }}
            />
          </Col>
        </Row>
      </Container>
    </section>
  );
};

// Pagination component (if it's not defined elsewhere)
function Pagination({ currentPage, itemsPerPage, totalItems, onPageChange }) {
  const totalPages = Math.ceil(totalItems / itemsPerPage);
  const pageNumbers = [];

  for (let i = 1; i <= totalPages; i++) {
    pageNumbers.push(i);
  }

  return (
    <nav className="d-flex justify-content-center">
      <ul className="pagination">
        {pageNumbers.map((page) => (
          <li
            key={page}
            className={`page-item ${page === currentPage ? "active" : ""}`}
          >
            <a
              href="#"
              className="page-link"
              onClick={() => onPageChange(page)}
            >
              {page}
            </a>
          </li>
        ))}
      </ul>
    </nav>
  );
}

export default Secondsec;
