import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Editor } from "@tinymce/tinymce-react";
import {
  TextField,
  Button,
  Grid,
  makeStyles,
  InputLabel,
} from "@material-ui/core";

import request from "../../utils/http-client";

const NewHomepage = () => {
  const [heading, setHeading] = useState("");
  const [desc, setDesc] = useState("");

  const navigate = useNavigate();

  const handleSubmit = (event) => {
    event.preventDefault();
    submitProduct(); // Call the submitProduct function here
  };

  const submitProduct = async () => {
    const baseUrl = `${process.env.REACT_APP_BACKEND}/api/add-contactpage`;
    const data = {
      heading,
      desc,
    };
    const result = await request({ url: baseUrl, method: "POST", body: data });
    if (result) {
      console.log(result);
      navigate("/pages-list");
    }

    // replace with actual submission logic
  };

  return (
    <div className="content-wrapper">
      <div className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1 className="m-0">Add New Page</h1>
            </div>
            {/* /.col */}
            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right"></ol>
            </div>
            {/* /.col */}
          </div>
          {/* /.row */}
        </div>
        {/* /.container-fluid */}
      </div>
      <div className="adminform-background px-3 mx-3 py-3">
        <form onSubmit={handleSubmit}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextField
                required
                label="Heading"
                value={heading}
                onChange={(event) => setHeading(event.target.value)}
                fullWidth
              />
            </Grid>

            <Grid item xs={12}>
              <InputLabel htmlFor="name-input">Description</InputLabel>

              <Editor
                apiKey={process.env.REACT_APP_TINYMCE_KEY}
                textareaName="product Description"
                value={desc}
                //onEditorChange={(e)=>SetDescription(e.target.value)}
                onEditorChange={(newValue, editor) => setDesc(newValue)}
                init={{
                  height: 500,
                  menubar: false,
                  plugins: [
                    "advlist autolink lists link image charmap print preview anchor",
                    "searchreplace visualblocks code fullscreen",
                    "insertdatetime media table paste code help wordcount",
                  ],
                  toolbar:
                    "undo redo | formatselect | " +
                    "bold italic backcolor | alignleft aligncenter " +
                    "alignright alignjustify | bullist numlist outdent indent | " +
                    "removeformat | help",
                  content_style:
                    "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                }}
              />
            </Grid>

            {/* <Grid item xs={12}>
          <TextField
            required
            label="Button One"
            value={buttonone}
            onChange={(event) => setButtonOne(event.target.value)}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            required
            label="Second Heading"
            value={secondheading}
            onChange={(event) => setSecondHeading(event.target.value)}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            required
            label="Second Description"
            value={desctwo}
            onChange={(event) => setDescTwo(event.target.value)}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            required
            label="Third Heading"
            value={thirdheading}
            onChange={(event) => setThirdHeading(event.target.value)}
            fullWidth
          />
        </Grid>*/}
            {/* <Grid item xs={12}>
        <InputLabel htmlFor="name-input">Description</InputLabel>

        <Editor
              textareaName="product Description"
              value={desc} 
              //onEditorChange={(e)=>SetDescription(e.target.value)} 
              onEditorChange={(newValue, editor) => setDesc(newValue)}
              init={{
                  height: 500,
                  menubar: false,
                  plugins: [
                      'advlist autolink lists link image charmap print preview anchor',
                      'searchreplace visualblocks code fullscreen',
                      'insertdatetime media table paste code help wordcount'
                  ],
                  toolbar: 'undo redo | formatselect | ' +
                  'bold italic backcolor | alignleft aligncenter ' +
                  'alignright alignjustify | bullist numlist outdent indent | ' +
                  'removeformat | help',
                  content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
              }}
          />
        </Grid>  */}
            {/* <Grid item xs={12}>
          <TextField
            required
            label="Third Button"
            value={thirdbutton}
            onChange={(event) => setThirdButton(event.target.value)}
            fullWidth
          />
        </Grid> */}

            <Grid item xs={12}>
              <Button type="submit" variant="contained" color="primary">
                Submit
              </Button>
            </Grid>
          </Grid>
        </form>
      </div>
    </div>
  );
};

export default NewHomepage;
