import React,{useState,useEffect} from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import './ReFunds.css'

const Secondsec = () => {
    const [partner, setpartner] = useState([]);

    const fetchpartnerpage = async () => {
        const response = await fetch(`${process.env.REACT_APP_BACKEND}/api/get-registeredfunds`);
        const result = await response.json();
        setpartner(result);
      };
      useEffect(() => {
          fetchpartnerpage();
      }, []);
  return (
        <section className='refunds-service-sec-2'>
            <Container>
                <Row>
                    <Col>
                    {partner.map((row, index) => (

<>
<h1>{row.secondheading}</h1>
<p>
{row.desctwo}
</p>
</>
))}
                    </Col>
                </Row>
            </Container>
        </section>

    )
}

export default Secondsec